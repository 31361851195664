export const hpPreloadAssetArray = [
  {
    asset_type: "PHOTO",
    model_url: "samplank/j1--at94389--gmail--dot94389--com",
    negative_prompt: "((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs)), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w",
    output_images: ["https://replicate.delivery/pbxt/ee5sH9PUbYhJH08pIz8c0dFfK97LDOgLO0w2ZVrxVAhtyeUKB/1711752278.jpg"],
    prediction_id: "s7@p.com-2024-03-29T22:43:43.735Z",
    prompt: "A film scene of herm waving a wand with blue electricity coming out of it, wizard, Hogwarts",
    status: "COMPLETE",
    timestamp: new Date("2024-03-29T22:43:43.736Z")
  },
  {
    asset_type: "VIDEO",
    model_url: "samplank/j1--at94389--gmail--dot94389--com",
    negative_prompt: "",
    output_images: ["https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wm_video_f56f37b5caf34b5083e54076bf888ff3.mp4?alt=media"],
    prediction_id: "s7@p.com-2024-03-29T22:49:16.858Z",
    prompt: "",
    status: "COMPLETE",
    timestamp: new Date("2024-03-29T22:49:16.858Z")
  },
  {
    asset_type: "PHOTO",
    model_url: "samplank/j1--at94389--gmail--dot94389--com",
    negative_prompt: "((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs)), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w",
    output_images: ["https://replicate.delivery/pbxt/JBuR61aH4boTLNe6cf7oFrwpLU72O3Wn7zgYHqfhhK7ovdKlA/1711750132.jpg"],
    prediction_id: "s7@p.com-2024-03-29T22:09:26.907Z",
    prompt: "A film scene of harry as a wizard, walking down the corridor of Hogwarts, full length",
    status: "COMPLETE",
    timestamp: new Date("2024-03-29T22:09:26.907Z")
  },
  {
    asset_type: "VIDEO",
    model_url: "samplank/j1--at94389--gmail--dot94389--com",
    negative_prompt: "",
    output_images: ["https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wm_video_f6c4ce0cca3546be9bc71dd5828afef2.mp4?alt=media"],
    prediction_id: "s7@p.com-2024-03-29T22:09:26.314Z",
    prompt: "",
    status: "COMPLETE",
    timestamp: new Date("2024-03-29T22:09:26.314Z")
  },
  {
    asset_type: "PHOTO",
    model_url: "samplank/j1--at94389--gmail--dot94389--com",
    negative_prompt: "((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs)), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w",
    output_images: ["https://replicate.delivery/pbxt/EFnM8jFGPfWxWSxhlRTFVFeksaQ6mVBToJDPtWLCWVf5AqKlA/1711775260.jpg"],
    prediction_id: "s7@p.com-2024-03-30T05:07:16.706Z",
    prompt: "A film scene of ronald  looking at the camera, wizard, disheveled, at hogwarts",
    status: "COMPLETE",
    timestamp: new Date("2024-03-30T05:07:16.706Z")
  },
  {
    asset_type: "VIDEO",
    model_url: "samplank/j1--at94389--gmail--dot94389--com",
    negative_prompt: "",
    output_images: ["https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wm_e56ef082ed2a47278c6e38d536c147d3_result_voice.mp4?alt=media"],
    prediction_id: "s7@p.com-2024-03-30T05:09:03.773Z",
    prompt: "",
    status: "COMPLETE",
    timestamp: new Date("2024-03-30T05:10:12.196212+00:00")
  }
];