import React, { useState } from 'react';

const PresetSelector = ({selected, setSelected, presets, multiSelect, onSelect, prefix, header }) => {

  const handleSelection = (preset) => {
    let updatedSelection;
    if (multiSelect) {
      if (selected.includes(preset)) {
        updatedSelection = selected.filter(item => item !== preset);
      } else {
        updatedSelection = [...selected, preset];
      }
    } else {
      updatedSelection = [preset];
    }
    setSelected(updatedSelection);
    onSelect(updatedSelection);
  };

  return (
    <div>
      { prefix ? (
        <div>
          <p className='text-gray-500 text-xs my-2'>Character</p>
          <p className='bg-gray-300 rounded p-2 my-1'>{prefix}</p>
        </div>
        ) : null
      }
      <div>
        {header && <p className='text-gray-500 text-xs my-2'>{header}</p>}
        <div className="flex flex-wrap gap-2">
          {presets.map((preset, index) => (
            <button
              key={index}
              className={`${
                selected.includes(preset) ? 'bg-green-500 text-black' : 'bg-black text-white'
              } transition-colors duration-200 ease-in-out text-xs py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black hover:bg-green-500 sm:text-sm`}
              onClick={() => handleSelection(preset)}
            >
              {preset}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PresetSelector;
