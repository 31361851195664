import React, { useEffect, useRef, useState } from 'react';

const RangeSlider = ({ duration, onStartChange, onEndChange, startValue, endValue, videoRef }) => {
    const sliderRef = useRef(null);
    const [isDragging, setIsDragging] = useState(null);

    useEffect(() => {
        // Ensure the slider reacts to external changes in startValue and endValue
        if (videoRef.current) {
            videoRef.current.currentTime = startValue;
        }
    }, [startValue, endValue, videoRef]);

    const handleMouseMove = (e) => {
        if (isDragging && sliderRef.current && videoRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const x = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
            const width = rect.width;
            const newTime = Math.min(Math.max(0, x / width), 1) * duration;

            if (isDragging === 'start' && newTime <= endValue) {
                onStartChange(newTime);
                videoRef.current.currentTime = newTime;
            } else if (isDragging === 'end' && newTime >= startValue) {
                onEndChange(newTime);
                videoRef.current.currentTime = newTime;
            }
        }
    };

    const handleMouseUp = () => {
        setIsDragging(null);
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp]);

    return (
        <div className='my-4'>
            <div ref={sliderRef} className="bg-gray-300 relative w-full h-1">
                <span
                    className="absolute h-4 w-4 bg-blue-500 rounded-full cursor-pointer left"
                    style={{ left: `${(startValue / duration) * 100}%`, transform: 'translate(-50%, -50%)' }}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        setIsDragging('start');
                    }}
                    onTouchStart={(e) => {
                        e.stopPropagation();
                        setIsDragging('start');
                    }}
                    title="start"
                />
                <span
                    className="absolute h-4 w-4 bg-blue-700 rounded-full cursor-pointer"
                    style={{ left: `${(endValue / duration) * 100}%`, transform: 'translate(-50%, -50%)' }}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        setIsDragging('end');
                    }}
                    onTouchStart={(e) => {
                        e.stopPropagation();
                        setIsDragging('end');
                    }}
                    title="end"
                />
            </div>
        </div>
    );
};

export default RangeSlider;
