import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { hpPreloadAssetArray } from '../AssetPreloads/HpPreloadAssetArray.js';
import { hpPreloadCharacterArray } from '../CharacterPreloads/hpPreloadCharacterArray.js';
import { truncateText } from '../utils.js';
import AllCharactersModal from './AllCharactersModal.jsx';
import AllPosesModal from './AllPosesModal.jsx';
import ChooseVoice from '../Components/ChooseVoice.jsx';
import { FaPersonRunning } from "react-icons/fa6";

const isAllFromPreloadData = (finetunePredictions, preloadDataArray) => {
    return finetunePredictions.every(prediction =>
      preloadDataArray.some(preloadItem => preloadItem.prediction_id === prediction.prediction_id)
    );
};

const CustomizationMode = {
  NONE: 0,
  POSE: 1,
  MOTION: 2,
};

function CharacterSelector({
    customCharacters = [],
    character,
    setCharacter,
    setPrompt,
    setPromptUpdatedManually,
    playgroundState,
    setSelectedPose,
    setIsAllMotionsModalOpen
}) {
    console.log(character);
    const { specialState } = useParams();
    const { currentUser } = useUser();
    const [showAllCharactersModal, setShowAllCharactersModal] = useState(false);
    const [topSlotCharacter, setTopSlotCharacter] = useState(null);
    const [currentSelection, setCurrentSelection] = useState(null);
    const [stockCharacters, setStockCharacters] = useState([]);
    const [currentPose, setCurrentPose] = useState(null);
    const [allPosesModalOpen, setAllPosesModalOpen] = useState(false);
    const [addSpeechModalOpen, setAddSpeechModalOpen] = useState(false);
    const [currentVoiceDescription, setCurrentVoiceDescription] = useState("");
    const [currentVoiceLabel, setCurrentVoiceLabel] = useState("");
    const [customizationMode, setCustomizationMode] = useState(CustomizationMode.NONE);

    const handleShowMore = () => setShowAllCharactersModal(true);

    const handleCharacterSelect = (character) => {
      setTopSlotCharacter(character);
      setCurrentSelection('top');
      setCharacter(character);
      setPromptUpdatedManually(false);
      setShowAllCharactersModal(false);
      setCurrentPose(null); // Reset pose
      setSelectedPose(null); // Reset selected pose
    };

    useEffect(() => {
      if (topSlotCharacter != null) {
        setCustomizationMode(CustomizationMode.NONE);
        setCurrentPose(null);
        setSelectedPose(null); // Reset selected pose
        setCharacter({...topSlotCharacter, character_type: ""});
      }
    }, [topSlotCharacter]);

    const handleTopSlotCharacterSelect = () => {
      if (topSlotCharacter) {
        setCurrentSelection('top');
        setCharacter(topSlotCharacter);
        setCustomizationMode(CustomizationMode.NONE);
        setCurrentPose(null); // Reset pose
        setSelectedPose(null); // Reset selected pose
        setCharacter({...topSlotCharacter, character_type: ""});
        setPromptUpdatedManually(false);
      } else {
        handleShowMore();
      }
    };

    const handleFreestyleSelect = () => {
      setCurrentSelection('freestyle');
      setCharacter({
          character_details: "Landscapes, objects, extras",
          character_name: "",
          character_sheet_url: "",
          character_type: "Freestyle",
          doc_id: "",
          model_url: "https://replicate.com/batouresearch/open-dalle-1.1-lora",
          reference_face_url: "https://replicate.delivery/pbxt/umN5244uLkKhMJnHgUR2TsjfBADUrc5p2cwu9mF7RLRcQYOJA/out-0.png",
          status: "complete",
          version: "2ade2cbfc88298b98366a6e361559e11666c17ed415d341c9ae776b30a61b196",
      });
      setPromptUpdatedManually(false);
      setCurrentPose(null); // Reset pose
      setSelectedPose(null); // Reset selected pose
    };

    const handlePoseSelect = (pose) => {
      setSelectedPose(pose);
      setCurrentPose(pose);
      setAllPosesModalOpen(false);
      setCustomizationMode(CustomizationMode.POSE);
      setCharacter(topSlotCharacter);
    };

    const openAllPosesModal = () => setAllPosesModalOpen(true);

    const handleVoiceSelected = (voiceLabel, description) => {
        setCurrentVoiceLabel(voiceLabel);
        setCurrentVoiceDescription(description);
        setCurrentSelection('sound');
        setCharacter({
            character_details: description,
            character_name: voiceLabel,
            character_sheet_url: "",
            character_type: "Voice",
            doc_id: "",
            model_url: "",
            reference_face_url: "",
            status: "complete",
            version: "",
        });
        setAddSpeechModalOpen(false);
        setCurrentPose(null); // Reset pose
        setSelectedPose(null); // Reset selected pose
    };

    const handleSetDialogue = () => {
        if (!currentVoiceDescription) {
            setAddSpeechModalOpen(true);
        } else {
            setCurrentSelection('sound');
            setCharacter({
              character_details: currentVoiceDescription,
              character_name: currentVoiceLabel,
              character_sheet_url: "",
              character_type: "Voice",
              doc_id: "",
              model_url: "",
              reference_face_url: "",
              status: "complete",
              version: "",
          });
          setCurrentPose(null); // Reset pose
          setSelectedPose(null); // Reset selected pose
        }
    };

    const handleAddMotion = () => {
      setCharacter({...character, character_type: "Motion"});
      setCustomizationMode(CustomizationMode.MOTION);

      setCurrentPose(null);
      setSelectedPose(null);
      setIsAllMotionsModalOpen(true)
    };

    const handleChangeVoice = (e) => {
        e.stopPropagation();
        setAddSpeechModalOpen(true);
    };

    useEffect(() => {
      if (!currentUser) return;
      const loadStockCharacters = async () => {
        const queryRef = query(collection(db, 'character-shared'), where('show_character', '==', true));
        const querySnapshot = await getDocs(queryRef);
        const fetchedCharacters = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        let combinedCharacters = [...fetchedCharacters];

        if (specialState === 'hp') {
          combinedCharacters = [...hpPreloadCharacterArray, ...combinedCharacters];
        }

        setStockCharacters(combinedCharacters);
      };

      loadStockCharacters();
    }, [specialState]);

    const noPredictionsNoCharacter = (playgroundState?.finetune_predictions?.length === 0 || isAllFromPreloadData(playgroundState.finetune_predictions, hpPreloadAssetArray)) && currentSelection == null;
    const highlightClasses = "glow";
  
    return (
      <div className={`py-4 ${noPredictionsNoCharacter ? highlightClasses : ''} p-2 rounded-lg`}>
        <span className="block text-gray-600 text-md mb-2 font-bold">Creation Mode</span>
        <div className="grid grid-cols-1 gap-4">
            <div
                className={`cursor-pointer flex border ${currentSelection === 'top' && topSlotCharacter ? 'border-4 border-blue-500' : 'border border-gray-300'} hover:border-green-500 rounded-lg hover:scale-105 duration-200 shadow-md bg-gray-100`}
                onClick={handleTopSlotCharacterSelect}
                style={{ maxHeight: '75px', width: '90%' }}
            >
            <div style={{ width: '25%', height: '100%', position: 'relative' }}>
              <img
                src={topSlotCharacter ? topSlotCharacter.reference_face_url : "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/ody_small.png?alt=media&token=2e51fdd9-e5da-4984-9a03-72087a492a0d"}
                alt={topSlotCharacter ? topSlotCharacter.character_name : "Use a character"}
                className="w-full h-full object-cover rounded-l"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center pl-4 relative">
              <span className="text-sm font-semibold">{topSlotCharacter ? truncateText(topSlotCharacter.character_name, 60) : "Character Scene"}</span>
              <p className="text-xs text-gray-700">{topSlotCharacter ? truncateText(topSlotCharacter.character_details, 60) : "For scenes with characters"}</p>
              {topSlotCharacter && (
                <button
                  className="absolute bottom-0 right-0 mb-2 mr-2 text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                  onClick={e => {
                    e.stopPropagation();
                    handleShowMore();
                  }}
                >
                  Change
                </button>
              )}
            </div>
          </div>
  
          <div
            className={`cursor-pointer flex border ${currentSelection === 'freestyle' ? 'border-4 border-blue-500' : 'border border-gray-300'} hover:border-green-500 rounded-lg hover:scale-105 duration-200 shadow-md bg-gray-100`}
            onClick={handleFreestyleSelect}
            style={{ maxHeight: '75px', width: '90%' }}
          >
            <div style={{ width: '25%', height: '100%', position: 'relative' }}>
              <img
                src={'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/freestyle_small.png?alt=media&token=b4bf16b0-436c-4798-935a-88d7b1baa3f9'}
                alt="Freestyle"
                className="w-full h-full object-cover rounded-l"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center pl-4">
              <span className="text-sm font-semibold">Freestyle Scene</span>
              <p className="text-xs text-gray-700">{"Landscapes and objects"}</p>
            </div>
          </div>
          <div
              className={`cursor-pointer flex border ${currentSelection === 'sound' ? 'border-4 border-blue-500' : 'border border-gray-300'} hover:border-green-500 rounded-lg hover:scale-105 duration-200 shadow-md bg-gray-100`}
              onClick={handleSetDialogue}
              style={{ maxHeight: '75px', width: '90%' }}
          >
              <div style={{ width: '25%', height: '100%', position: 'relative' }}>
                  <img
                      src={`${process.env.PUBLIC_URL}/${`waveform.svg`}`}
                      alt="Create Sound"
                      className="w-full h-full object-cover rounded-l bg-gray-100"
                  />
              </div>
              <div className="flex-1 flex flex-col justify-center pl-4 relative">
                  <span className="text-sm font-semibold">{currentVoiceDescription || "Dialogue"}</span>
                  <p className="text-xs text-gray-700">For characters and narration</p>
                  {currentSelection === 'sound' && (
                      <button
                          className="absolute bottom-0 right-0 mb-2 mr-2 text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                          onClick={handleChangeVoice}
                      >
                          Change
                      </button>
                  )}
              </div>
          </div>
          {currentSelection === 'top' && topSlotCharacter && topSlotCharacter.weights && (
            <div className="">
              <span className="block text-gray-600 text-md mb-2 font-bold">Customization</span>
              <div
                className={`cursor-pointer flex ${customizationMode == CustomizationMode.POSE ? 'border-2 border-blue-500' : 'border border-gray-300'} hover:border-blue-500 rounded-lg hover:scale-105 duration-200 mb-4 shadow-md bg-gray-100`}
                onClick={openAllPosesModal}
                style={{ maxHeight: '75px', width: '90%' }}
              >
                <div className='w-1/4 flex items-center'>
                  <img
                    src={currentPose?.square_image || "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/standing_01.png?alt=media&token=fc661c4b-615e-40a1-ba64-3e3d9579765ehttps://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/standing_01.png?alt=media&token=3aa9b59a-675d-454a-8c7b-526e1912a043"}
                    alt="Choose Pose"
                    className="max-h-full rounded-l"
                  />
                </div>
                <div className="flex-1 flex flex-col justify-center pl-4 relative">
                  <span className="text-sm font-semibold">Set Pose</span>
                  <p className="text-xs text-gray-700">Optional</p>
                  <div className="absolute bottom-0 right-0 mb-2 mr-2 flex">
                    {currentPose && (
                      <>
                        <button
                          className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            openAllPosesModal();
                          }}
                        >
                          Change
                        </button>
                        <button
                          className="ml-2 text-xs bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentPose(null);
                            setSelectedPose(null);
                            setCustomizationMode(CustomizationMode.NONE);
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`cursor-pointer flex ${customizationMode === CustomizationMode.MOTION ? 'border-2 border-blue-500' : 'border border-gray-300'} hover:border-blue-500 rounded-lg hover:scale-105 duration-200 mb-4 shadow-md bg-gray-100 group`}
                onClick={() => handleAddMotion()}
                style={{ maxHeight: '75px', width: '90%' }}
              >
                <div className="w-1/4 flex items-center">
                  <FaPersonRunning className="text-gray-500 w-4/5 h-4/5 group-hover:text-orange-500 duration-200" />
                </div>
                <div className="flex-1 flex flex-col justify-center pl-4 relative">
                  <span className="text-sm font-semibold text-gray-800">Add Motion</span>
                  <p className="text-xs text-gray-700">Optional</p>
                  <div className="absolute bottom-0 right-0 mb-2 mr-2 flex">
                    {customizationMode === CustomizationMode.MOTION && (
                      <>
                        <button
                          className="ml-2 text-xs bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomizationMode(CustomizationMode.NONE);
                            setCharacter({...character, character_type: ""});
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
  
        {showAllCharactersModal && (
          <AllCharactersModal
            customCharacters={customCharacters}
            stockCharacters={stockCharacters}
            setCharacter={handleCharacterSelect}
            onClose={() => setShowAllCharactersModal(false)}
          />
        )}

        {addSpeechModalOpen && (
            <ChooseVoice
                isOpen={addSpeechModalOpen}
                onClose={() => setAddSpeechModalOpen(false)}
                onVoiceSelected={handleVoiceSelected}
            />
        )}
  
        {allPosesModalOpen && (
          <AllPosesModal
            isOpen={allPosesModalOpen}
            onClose={() => setAllPosesModalOpen(false)}
            onPoseSelect={handlePoseSelect}
          />
        )}
      </div>
    );
  }

export default CharacterSelector;
