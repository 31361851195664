import React, { useState } from 'react';

const NameInput = ({ onNameComplete, backButton }) => {
  const [name, setName] = useState('');

  const handleInputChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    if (name.trim() !== '') {
      onNameComplete(name.trim());
    }
  };

  return (
    <div className="upload-container mt-10 bg-gray-100 p-4 rounded-md max-w-4xl mx-auto">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800">Enter the person's name</h2>
        </div>
        <input
          type="text"
          className="block w-full p-2  mt-1 border rounded border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          placeholder="Type name here..."
          value={name}
          onChange={handleInputChange}
        />
        <div className="flex justify-between mt-4">
          <button
            onClick={backButton}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            disabled={!name.trim()}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-blue-300"
          >
            Next
          </button>
        </div>
    </div>
  );
};

export default NameInput;