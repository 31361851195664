import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../Firebase.js';
import { useUser } from '../contexts/UserContext';
import MenuBar from '../MenuBar';

function ResultPage() {
  const { currentUser } = useUser();
  const { resultId } = useParams();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser && resultId) {
      const username = currentUser.email || currentUser.uid;
      const docRef = doc(db, `character-users/${username}/template-results`, resultId);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setResult(docSnap.data());
          setLoading(false);
        } else {
          console.log("No such document!");
          setResult(null);
          setLoading(false);
        }
      }, (error) => {
        console.error("Error fetching the document: ", error);
        setLoading(false);
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }
  }, [currentUser, resultId]);

  return (
    <div>
      <MenuBar />
      <div className="mt-12 p-6">
        <div className="video-container max-w-xl mx-auto">
          {loading ? (
            <div className="pt-10 text-lg font-semibold text-gray-500">
              We're working on your video! It will be ready here within 30 minutes.
            </div>
          ) : result && result.is_complete ? (
            <>
              <p className="pt-10 text-lg font-semibold text-gray-500 mb-6">
                Your video is here, Pookie! Time to share it with your friends!
              </p>
              <video className="w-full h-auto rounded-xl shadow-lg" controls>
                <source src={result.result_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="mt-4">
                <a href="/playground" target="_blank" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Make another video
                </a>
              </div>
            </>
          ) : (
            <div className="text-lg font-semibold text-gray-500">
              We're working on your video! It will be ready here within 30 minutes.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultPage;
