export const hpPreloadCharacterArray = [
    {
      character_name: "herm",
      character_details: "long bushy brown hair, wearing a black cloak",
      character_sheet_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-85e57da1-6b4e-4a3f-9a59-f2d7c39373aa-image-1.png?alt=media",
      character_type: "a british teenage girl",
      model_url: "samplank/j1--at94389--gmail--dot94389--com",
      reference_face_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-3b065b59-c677-4660-9e6d-2cc12b03ae7d-image-1.png?alt=media",
      status: "complete",
      user: "j1@gmail.com",
      version: "fb843b958328139e0c90419e0cd58a1df716826c8494b479caa1624b5f1a88e9"
    },
    {
      character_name: "ronald",
      character_details: "shaggy red hair, ugly, black cloak",
      character_sheet_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-892f30d4-4811-4e5d-ad65-525e782e70f7-image-1.png?alt=media",
      character_type: "a teenage british boy",
      model_url: "samplank/j1--at94389--gmail--dot94389--com",
      reference_face_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-b8e6612d-e3f9-40fa-8275-04fd23dd76cd-image-1.png?alt=media",
      status: "complete",
      user: "j1@gmail.com",
      version: "aa13c4378b7a713dcefb167e6af446955961a521be4c2dce571c84c9b2b9d4ee"
    },
    {
      character_name: "harry",
      character_details: "round black glasses, short black hair, ugly, black robe",
      character_sheet_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-db9e810c-de8e-4a09-8614-de201a7c6cb8-image-1.png?alt=media",
      character_type: "an english boy",
      model_url: "samplank/j1--at94389--gmail--dot94389--com",
      reference_face_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-59bb9633-3b9f-4ea7-9521-948c5542f493-image-1.png?alt=media",
      status: "complete",
      user: "j1@gmail.com",
      version: "90120d41cd24bc9dc30756d78999075f4e7a1440faef57b96ca032c47832c4ac"
    },
    {
      character_name: "albus",
      character_details: "thin wire glasses, white hair, white beard, pointy hat, medieval, purple cloak",
      character_sheet_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-4b0d125b-f465-47a0-be2d-afa6e14da28b-image-1.png?alt=media",
      character_type: "an old white wizard",
      model_url: "samplank/j1--at94389--gmail--dot94389--com",
      reference_face_url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/app_playground%2Fj1%40gmail.com-7a1ebdcd-c3dc-4c72-9502-c02a3290a90e-image-1.png?alt=media",
      status: "complete",
      user: "j1@gmail.com",
      version: "ba2f125e98a7a612fe8d2ebf06c45eaf8e491ccddaa23f13f33e03f8117a4a5d"
    }
];
  