import React, { useState, useEffect, useRef } from 'react';

const PreviewModal = ({ clips, onClose }) => {
  const [currentClipIndex, setCurrentClipIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, clips.length);
    playCurrentClip();
  }, [currentClipIndex]);

  const playCurrentClip = () => {
    const video = videoRefs.current[currentClipIndex];
    const clip = clips[currentClipIndex];

    if (!video) return;

    video.playbackRate = clip.speed ? clip.speed : 1; // Set playback rate based on clip's speed attribute
    video.currentTime = clip.startTrim;

    const onEnded = () => {
      setCurrentClipIndex((currentClipIndex + 1) % clips.length);
    };

    video.addEventListener('ended', onEnded);
    video.play().catch((e) => console.error('Error attempting to play video:', e));

    return () => {
      video.removeEventListener('ended', onEnded);
    };
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50"
      onClick={onClose}
      tabIndex="-1"
    >
      <div
        className="relative bg-white rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {clips.map((clip, index) => (
          <video
            key={index}
            ref={el => videoRefs.current[index] = el}
            className={`w-full ${index === currentClipIndex ? 'block' : 'hidden'}`}
            src={clip.url}
            muted
            style={{ aspectRatio: '16 / 9' }}
          ></video>
        ))}
      </div>
    </div>
  );
};

export default PreviewModal;
