import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from './ProgressBar';
import { MdClose } from 'react-icons/md';
import { IoMdDownload } from "react-icons/io";

export default function ResultsGrid({
  results,
  captionKey = 'raw_prompt',
  showImages,
  photoType,
  showActionButton,
  actionButtonText,
  onAction,
  showDeleteButton = false,
  onDeleteAsset = () => {},
  showAddMore,
  addMoreText,
  onAddSpeech = null,
  onAddMotion = null,
  specialState = null,
  currentUser,
}) {
  const resultsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(results.length / resultsPerPage);
  const videoRefs = useRef(new Array(resultsPerPage));
  const username = currentUser?.email || currentUser?.uid;

  const resultsSortedRecent = [...results].reverse().slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );

  const handleImageClick = (image, prompt) => {
    showImages([[image, prompt]]);
  };

  const handleSelect = (resIndex, modelUrl, referenceFaceUrl, imgIndex) => {
    onAction(results.length - resIndex - 1, modelUrl, referenceFaceUrl, imgIndex);
  };

  const formatPrompt = (raw_prompt) => {
    if (!raw_prompt) return '';
    return raw_prompt.replaceAll('///', ', ');
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleMouseEnter = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.play().catch(error => console.error('Error attempting to play video:', error));
    }
  };

  const handleMouseLeave = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.pause();
    }
  };

  const downloadVideo = async (videoUrl) => {
    if (!currentUser || !currentUser.email) {
      console.error('User is not defined or does not have an email');
      return;
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const data = {
      user: currentUser.email,
      event_name: 'playground-download-clip',
      video_url: videoUrl,
    };

    try {
      await fetch('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/log', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (isSafari) {
        try {
          await navigator.clipboard.writeText(videoUrl);
          alert('Video URL copied to clipboard!');
        } catch (error) {
          console.error('Failed to copy video URL:', error);
          alert('Please copy manually and open in a new tab:\n' + videoUrl);
        }
      } else {
        window.open(`https://ai-apps-backend-80af17cb1aaa.herokuapp.com/download-video?videoUrl=${encodeURIComponent(videoUrl)}`, '_blank');
      }
    } catch (error) {
      console.error('Error logging event to backend:', error);
    }
  };

  const MotionStillGridItem = ({
    character_name,
    model_url,
    model_url_original,
    reference_face_url,
    image_type,
    prediction_id,
    id,
    caption,
    prompt,
    negative_prompt,
    image,
    index,
    gridKey
  }) => {
    const actionButtonText = "Add motion";
    return (
      <div key={gridKey} className="group bg-gray-100 rounded-lg overflow-hidden shadow-lg over:shadow-xl transition-shadow duration-300 ease-in-out relative">
        <img
          src={image}
          alt={`${id} output`}
          className="w-full h-48 object-cover cursor-pointer transform group-hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() => handleImageClick(image, formatPrompt(caption))}
        />
        <div className="flex justify-between items-center p-2">
          <p className={`text-xs font-mono text-gray-800 ${showActionButton ? 'max-w-[80%]' : ''}  line-clamp-3`}>
            {formatPrompt(caption)}
          </p>
          <div className="flex space-x-2">
            <button
              onClick={() => onAddMotion(index, character_name, model_url, model_url_original, reference_face_url, image, prompt, negative_prompt)}
              className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded text-xs"
            >
              {actionButtonText}
            </button>
          </div>
        </div>
        {showDeleteButton && (
          <button
            onClick={() => onDeleteAsset(prediction_id)}
            className="absolute top-0 right-0 mt-2 mr-2 bg-gray-300 hover:bg-gray-400 text-gray-500 font-bold p-1 rounded hover:bg-red-600 hover:text-white"
          >
            <MdClose size="1em" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center my-2 text-sm">
        <div className="text-center text-gray-500">
          {totalPages > 1 && <span>Page {currentPage} of {totalPages}</span>}
        </div>
        <div className='space-x-2'>
          {currentPage > 1 && (
            <button
              onClick={goToPreviousPage}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-2 rounded"
            >
              Previous
            </button>
          )}
          {currentPage < totalPages && (
            <button
              onClick={goToNextPage}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-2 rounded"
            >
              Next
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {resultsSortedRecent.flatMap((result, resIndex) =>
          result.status === "PROCESSING" ? (
            <div key={result.prediction_id} className="bg-gray-100 rounded-lg overflow-hidden shadow-lg p-4 text-center relative">
              <div className="flex flex-col justify-center items-center h-48 space-y-2">
                <ProgressBar
                  duration={result.asset_type === "VIDEO" ? 270000 : 30000}
                  onCompletion={() => console.log('Reached 95%')}
                  key={result.prediction_id}
                />
                <p className='text-sm text-gray-500'>Generating scenes takes time. We're working on making this faster!</p>
              </div>
              {result.asset_type === "VIDEO" && (
                <p className={`text-xs font-mono text-black max-w-[100%]`}>
                  Video
                </p>
              )}
              <p className="text-xs font-mono text-gray-700 p-2 truncate h-full max-h-10">
                {formatPrompt(result[captionKey])}
              </p>
              {showDeleteButton && (
                <button
                  onClick={() => onDeleteAsset(result.prediction_id)}
                  className="absolute top-0 right-0 mt-2 mr-2 bg-gray-300 hover:bg-gray-400 text-gray-500 font-bold p-1 rounded hover:bg-red-600 hover:text-white"
                >
                  <MdClose size="1em" />
                </button>
              )}
            </div>
          ) : result.status === "ERROR" ? (
            <div key={resIndex} className="bg-white rounded-lg overflow-hidden shadow-lg p-4 text-center relative">
              <div className="flex justify-center items-center h-48">
                <p className="text-m">There was an issue. Please try again!</p>
              </div>
              <p className="text-xs font-mono text-gray-700 p-2 truncate h-full max-h-4">
                {formatPrompt(result[captionKey])}
              </p>
              {showDeleteButton && (
                <button
                  onClick={() => onDeleteAsset(result.prediction_id)}
                  className="absolute top-0 right-0 mt-2 mr-2 bg-gray-300 hover:bg-gray-400 text-gray-500 font-bold p-1 rounded hover:bg-red-600 hover:text-white"
                >
                  <MdClose size="1em" />
                </button>
              )}
            </div>
          ) : result.output_images.map((image, imgIndex) => {
            const key = `${result.prediction_id}-${imgIndex}`;
            const isVideo = image.endsWith('.mp4?alt=media') || image.endsWith('.mp4') || image.endsWith('.webm') || image.endsWith('.ogg');
            const isAudio = image.endsWith('.wav?alt=media') || image.endsWith('.wav');
            const isMotionStill = result.image_type == "character_weights_motion";
            const audioBannerUrl = `${process.env.PUBLIC_URL}/${`waveform.svg`}`;

            if (isMotionStill)
              return (
                <MotionStillGridItem
                  character_name={result.character_name}
                  model_url={result.model_url}
                  model_url_original={result.model_url_original}
                  reference_face_url={result.reference_face_url}
                  image_type={result.image_type}
                  prediction_id={result.prediction_id}
                  id={null}
                  caption={result[captionKey]}
                  prompt={result.prompt}
                  negative_prompt={result.negative_prompt}
                  image={image}
                  index={imgIndex}
                  gridKey={key}
                />
              )

            return (
              <div key={key} className="group bg-gray-100 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out relative">
                {isVideo ? (
                  <>
                    <video
                      ref={(el) => videoRefs.current[resIndex * resultsPerPage + imgIndex] = el}
                      className="w-full h-48 object-cover cursor-pointer transform group-hover:scale-105 transition-transform duration-300 ease-in-out"
                      onClick={() => handleImageClick(image, formatPrompt(result[captionKey]))}
                      preload="metadata"
                      loop
                      muted
                      onMouseEnter={() => handleMouseEnter(resIndex * resultsPerPage + imgIndex)}
                      onMouseLeave={() => handleMouseLeave(resIndex * resultsPerPage + imgIndex)}
                    >
                      <source src={image} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div className="flex justify-between items-center mt-2 p-2">
                      <p className="text-xs font-mono text-gray-800 max-w-[80%]">
                        🎥 Video
                      </p>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => window.open(specialState ? `/compose/${specialState}` : '/compose', '_blank')}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
                        >
                          Edit in Composer
                        </button>
                        <button
                          onClick={() => downloadVideo(image)}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
                        >
                          <IoMdDownload />
                        </button>
                      </div>
                    </div>
                  </>
                ) : isAudio ? (
                  <>
                    <img
                      src={audioBannerUrl}
                      alt="Audio waveform"
                      className="w-full h-48 object-cover"
                    />
                    <audio
                      controls
                      className="w-full"
                      onClick={() => handleImageClick(image, formatPrompt(result[captionKey]))}
                    >
                      <source src={image} type="audio/wav" />
                      Your browser does not support the audio element.
                    </audio>
                  </>
                ) : (
                  <img
                    src={image}
                    alt={`${result.id} output`}
                    className="w-full h-48 object-cover cursor-pointer transform group-hover:scale-105 transition-transform duration-300 ease-in-out"
                    onClick={() => handleImageClick(image, formatPrompt(result[captionKey]))}
                  />
                )}
                <div className="flex justify-between items-center p-2">
                  <p className={`text-xs font-mono text-gray-800 ${showActionButton ? 'max-w-[80%]' : ''}  line-clamp-3`}>
                    {formatPrompt(result[captionKey])}
                  </p>
                  {showActionButton && !isVideo && !isAudio && (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => onAction(resIndex, result.model_url, result.reference_face_url, image, result.image_type ? result.image_type : "")}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
                      >
                        {actionButtonText}
                      </button>
                      {typeof onAddSpeech === 'function' && result.model_url !== 'https://replicate.com/stability-ai/sdxl' && (
                        <button
                          onClick={() => onAddSpeech(resIndex, result.model_url, image)}
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xs"
                        >
                          Add Speech
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {showDeleteButton && (
                  <button
                    onClick={() => onDeleteAsset(result.prediction_id)}
                    className="absolute top-0 right-0 mt-2 mr-2 bg-gray-300 hover:bg-gray-400 text-gray-500 font-bold p-1 rounded hover:bg-red-600 hover:text-white"
                  >
                    <MdClose size="1em" />
                  </button>
                )}
              </div>
            );
          })
        )}
      </div>
      <div className="flex justify-between items-center mt-4 text-sm">
        <div className="text-center text-gray-500">
          {totalPages > 1 && <span>Page {currentPage} of {totalPages}</span>}
        </div>
        <div>
          {currentPage > 1 && (
            <button
              onClick={goToPreviousPage}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-2 mx-2 rounded"
            >
              Previous
            </button>
          )}
          {currentPage < totalPages && (
            <button
              onClick={goToNextPage}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-2 mx-2 rounded"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
