import React, { useState, useEffect, useRef } from "react";
import { uploadVideoToFirebase } from '../Firebase.js';
import BarLoader from 'react-spinners/BarLoader';

export default function AssetViewer({ finetunePredictions, uploadedVideos, userId, onAddToComposer }) {
  const assetsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [uploading, setUploading] = useState({});
  const [assets, setAssets] = useState([]);
  const videoRefs = useRef(new Array(assetsPerPage));
  const observer = useRef(null);

  useEffect(() => {
    const combinedAssets = [
      ...finetunePredictions.filter(({ asset_type }) => asset_type === 'VIDEO')
        .flatMap(({ output_images, timestamp }) => output_images.map(url => ({ url, timestamp }))),
      ...uploadedVideos.map(({ url, timestamp }) => ({ url, timestamp }))
    ].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setAssets(combinedAssets);
  }, [finetunePredictions, uploadedVideos]);

  const currentAssets = assets.slice((currentPage - 1) * assetsPerPage, currentPage * assetsPerPage);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.src = video.dataset.src;
          observer.current.unobserve(video);
        }
      });
    }, { threshold: 0.1 });

    const currentRefs = videoRefs.current.slice(0, currentAssets.length);
    currentRefs.forEach(ref => ref && observer.current.observe(ref));

    return () => observer.current.disconnect();
  }, [currentPage, assets]);

  useEffect(() => {
    currentAssets.forEach(({ url }, index) => getVideoThumbnail(url, index));
  }, [currentAssets]);
  const handleUpload = async (event) => {
    const file = event.target.files[0]; // Get the file from the input element
    if (!file) {
      return; // Exit if no file is selected
    }
  
    const temporaryId = Date.now(); // Create a temporary ID for tracking upload progress
    setUploading((prev) => ({ ...prev, [temporaryId]: 0 })); // Initialize upload progress
  
    try {
      // Call the uploadVideoToFirebase function, passing the selected file, userId, and a callback for tracking upload progress
      const videoUrl = await uploadVideoToFirebase(file, userId, (progress) => {
        setUploading((prev) => ({ ...prev, [temporaryId]: progress })); // Update upload progress
      });
  
      // Here you can handle the uploaded video URL as needed
      console.log('Uploaded video URL:', videoUrl);
  
      // Remove the temporary upload progress tracking after the upload is complete
      setUploading((prev) => {
        const updated = { ...prev };
        delete updated[temporaryId];
        return updated;
      });
    } catch (error) {
      console.error("Error uploading video:", error); // Handle any errors that occur during the upload
    }
  };  

  const handleMouseEnter = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.play().catch(error => console.error('Error attempting to play video:', error));
    }
  };

  const handleMouseLeave = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.pause();
    }
  };

  const handleDragStart = (event, assetUrl, index) => {
    event.dataTransfer.setData('text/asset-url', assetUrl);
    const video = videoRefs.current[index];
    event.dataTransfer.setData('text/asset-duration', video.duration.toString());
  };

  const getVideoThumbnail = (videoUrl, index) => {
    // Create a temporary video element
    const video = document.createElement('video');
    video.src = videoUrl;
    video.crossOrigin = "anonymous"; // Handle CORS if needed
    video.addEventListener('loadedmetadata', () => {
      video.currentTime = 1; // Seek to the first second to ensure we have a frame
    });
  
    video.addEventListener('seeked', () => {
      // Create a canvas to capture the frame
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
      // Update the video element in your refs with the captured frame as the poster
      const videoElement = videoRefs.current[index];
      if (videoElement) {
        videoElement.setAttribute('poster', canvas.toDataURL('image/jpeg'));
      }
    });
  };
  
  useEffect(() => {
    currentAssets.forEach(({ url }, index) => getVideoThumbnail(url, index));
  }, [currentAssets]);  

  return (
    <div className="h-1/2 p-4 overflow-y-auto bg-gray-100 mx-2">
      <h2 className="text-xl text-gray-800 font-semibold mb-4">Video Clips</h2>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-4">
        {Object.keys(uploading).map(key => (
          <div key={key} className="w-full h-full bg-white flex justify-center items-center">
            <BarLoader width="80%" color="#3699FF" />
          </div>
        ))}
        {currentAssets.map((asset, index) => (
          <div key={`${currentPage}-${index}`} className="video-clip-container">
            <video
              key={`${currentPage}-${index}`}
              ref={el => videoRefs.current[index] = el}
              data-src={asset.url}
              draggable
              onDragStart={(e) => handleDragStart(e, asset.url, index)}
              loop
              preload="metadata"
              className="w-full border rounded shadow"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              // The poster attribute will be set dynamically by the getVideoThumbnail function
            >
              Your browser does not support the video tag.
            </video>
            <button
              className="add-to-composer-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mt-2"
              onClick={() => onAddToComposer(asset.url)} // Call the function when the button is clicked
            >
              Add to Composer
            </button>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4">
        {currentPage > 1 && (
          <button onClick={() => setCurrentPage(currentPage - 1)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Previous
          </button>
        )}
        <span>Page {currentPage} of {Math.ceil(assets.length / assetsPerPage)}</span>
        {currentPage * assetsPerPage < assets.length && (
          <button onClick={() => setCurrentPage(currentPage + 1)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Next
          </button>
        )}
      </div>
      <div className="mt-4 text-center">
        <input type="file" accept="video/*" onChange={handleUpload} style={{ display: 'none' }} id="video-upload" />
        <label htmlFor="video-upload" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer inline-block">
          Upload External Clip
        </label>
      </div>
    </div>
  );
}
