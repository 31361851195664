export const suggestedMotionsArray = [
    {model: 'KF', title: "Leg in the Air",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Flegintheair.mp4?alt=media&token=601b9773-c804-4859-b1d0-3b5d71b045e4", 'camera_zoom': 3.0},
    {model: '3D', title: 'Breakdance_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Breakdance_1.mp4', 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing7",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing7.mp4?alt=media&token=086c7d43-f864-4159-9ea3-8909f9ba9c96", 'camera_zoom': 3.0},    
    {model: '3D', title: 'Applause_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Applause_1.mp4', 'camera_zoom': 3.0},
    {model: 'KF', title: "Bridge",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fbridge.mp4?alt=media&token=b6d84aae-4098-47dc-b4ec-0f2565de4639", 'camera_zoom': 3.0},
    {model: '3D', title: 'Handstand_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Handstand_1.mp4', 'camera_zoom': 3.0},
    {model: 'KF', title: "Climbing Stairs",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fclimbingstairs.mp4?alt=media&token=ccf577f8-142d-4d0a-82af-a426884dc62d", 'camera_zoom': 3.0},
    {model: '3D', title: 'Getting_attention', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Getting_attention.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Streetfighting', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Streetfighting.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Jumping_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Jumping_1.mp4', 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing10",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing10.mp4?alt=media&token=098cba38-be2d-4c62-9ae5-9cc3befd82df", 'camera_zoom': 3.0},
    {model: 'KF', title: "Being shot",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fbeingshot.mp4?alt=media&token=4970a86e-af3f-40c9-bf16-4ff2dbba099b", 'camera_zoom': 3.0},
]

export const motionInputsArray = [
    // {title: "Walking forward", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FWalking_forward_short.mp4?alt=media&token=9bfa961a-4f6e-4d45-a012-aadfb732fc36"},
    // {title: "Walking close up", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_walking1.mp4?alt=media"},
    // {title: "Walking left", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2Fwalking_left_semantic_square.mp4?alt=media&token=468e2044-a89c-4892-a75e-5dfac79ace01"},
    // {title: "Walking right", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00jits-ssh-file-transfer%2Fwalking_right_dwpose_semantic.mp4?alt=media&token=57ff914a-3383-4df5-9fcb-9c25950d86f8"},
    // {title: "Sitting down", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FSitting_down_short.mp4?alt=media&token=92411bad-8497-4b4d-af38-bfa6e1e481d1"},
    // {title: "Shuffling left", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FShuffling_left_short.mp4?alt=media&token=0e6b73e2-1686-4f00-8bb4-3de025748f43"},
    // {title: "Shuffling right", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2Fshuffling_right.mp4?alt=media&token=6a21e205-f56d-4a6b-a7c7-d2c76abe9858"},
    // {title: "Looking around", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLooking_around_short.mp4?alt=media&token=d17bfa47-053a-41d4-8fc9-6ca9c0f754ca"},
    // {title: "Looking around close up", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLooking_around_close_short.mp4?alt=media&token=2f3984c9-0964-4115-81a9-a69e04548a10"},
    // {title: "Waving frantically", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FWaving_frantically_short.mp4?alt=media&token=2d8dd4e2-06ac-4d2c-9707-7bdf8a863ec0"},
    // {title: "Waving, opposite hand down", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FWaving_opposit_hand_down_short.mp4?alt=media&token=d85cba11-9997-400f-91aa-7780482a729f"},
    // {title: "Waving, opposite hand at side", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FWaving_short.mp4?alt=media&token=057672f0-d814-4756-8b57-5db7a571fb9c"},
    // {title: "Receiving soccer ball", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FReceiving_soccer_ball_short.mp4?alt=media&token=4fcb1a63-0d4b-46bb-b359-8978a8c62496"},
    // {title: "Drawing a weapon", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FDrawing_weapon_short.mp4?alt=media&token=87316178-c61e-430b-949f-f3f3a68f6fc8"},
    // {title: "Boxing", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2Fpunching.mp4?alt=media&token=8d7a07e9-cfad-471f-8d49-f67e0aef7686" },
    // {title: "Punching close up", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/punching_zoomed.mp4?alt=media&token=d9578032-ae27-4772-a497-c4cbcf30d82a"},
    // {title: "Tossing", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_throwingup.mp4?alt=media"},
    // {title: "Knocking", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_scratching1.mp4?alt=media"},
    // {title: "Dancing", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_dancing.mp4?alt=media"},
    // {title: "Throwing", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_throwing1.mp4?alt=media"},
    // {title: "Kicking", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FKicking_short.mp4?alt=media&token=31ac4577-aa34-4e4d-9771-b919265653b7"},
    // {title: "Jumping1", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_jumping1.mp4?alt=media"},
    // {title: "Squeezing", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_handgestures1.mp4?alt=media"},
    // {title: "Twirling", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/densepose_twirling.mp4?alt=media"},
    // {title: "Rising", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FRising_Short.mp4?alt=media&token=e50db0c0-9ad0-4d16-a9e6-8a2232f85ec7"},
    // {title: "Gesturing", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FGesturing_short.mp4?alt=media&token=710a88bc-a583-4e93-bb4c-bf7226e92d36"},
    // {title: "Left jab, close up", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLeft_jab_close_short.mp4?alt=media&token=80c470a5-d95d-436e-ae4f-a289d1bae247"},
    // {title: "Left jab", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLeft_jab_short.mp4?alt=media&token=4b8dd8eb-2936-4ce8-b5f6-f6581a3978ea"},
    // {title: "Left right punch, close up", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLeft_right_punch_close_short.mp4?alt=media&token=e4f407d4-0756-4968-a8b3-e62fe691f1a4"},
    // {title: "Left right punch", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLeft_right_punch_short.mp4?alt=media&token=972f6017-ff77-480e-aa32-c374a16954de"},
    // {title: "Left right punch", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FLeft_right_punch_short.mp4?alt=media&token=972f6017-ff77-480e-aa32-c374a16954de"},
    // {title: "Right jab, close up", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FRight_jab_close_short.mp4?alt=media&token=fec73d77-3b65-4244-8ae6-a2de3896cfa2"},
    // {title: "Right jab", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/00motion-videos%2FRight_jab_short.mp4?alt=media&token=737a2e28-7018-49d6-b996-cc049a3e5003"},
    
    {model: 'KF', title: "Kicking",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_kicking_soccer.mp4?alt=media&token=df4a763b-d654-4f76-a778-d693fb6c565c", 'camera_zoom': 3.0},
    {model: 'KF', title: "Picking something up",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_picking_something_up.mp4?alt=media&token=838fa601-d444-4373-b7f0-23e8b4757db1", 'camera_zoom': 3.0},
    {model: 'KF', title: "Putting hand up",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_saying_stop.mp4?alt=media&token=d762cc14-7828-49da-bdf2-6f49416b1cb3", 'camera_zoom': 3.0},
    {model: 'KF', title: "Skipping",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_skipping.mp4?alt=media&token=000d5f37-7e1f-423f-978d-70ce025d8c30", 'camera_zoom': 3.0},
    {model: 'KF', title: "Squatting",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_squats_covers_fac.mp4?alt=media&token=f347f209-1fb4-483d-9491-02f163b84632", 'camera_zoom': 3.0},
    {model: 'KF', title: "Moving hands",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_sword.mp4?alt=media&token=9590e5f9-f2ac-43e4-bd9b-58fe15501493", 'camera_zoom': 3.0},
    
    // overlapping hand looks bad
    // {title: "Putting hand up, cropped",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_saying_stop_cropped.mp4?alt=media&token=b576c088-1e81-4cbe-a116-4b757c9a074b"},
    {model: 'KF', title: "Kicking, cropped",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/doll_kicking_soccer_cropped.mp4?alt=media&token=25215c81-30c5-4e1a-9890-6a795db4b68e", 'camera_zoom': 3.0},

    {model: 'KF', title: "Ballet",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fballet1.mp4?alt=media&token=58b40f4f-e39e-4cda-8d5e-06dd177c4e7e", 'camera_zoom': 3.0},
    {model: 'KF', title: "Being shot",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fbeingshot.mp4?alt=media&token=4970a86e-af3f-40c9-bf16-4ff2dbba099b", 'camera_zoom': 3.0},
    {model: 'KF', title: "Boxing2",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fboxing2.mp4?alt=media&token=1e4f3328-f94f-4b32-a6fa-6d8b635b39f0", 'camera_zoom': 3.0},
    {model: 'KF', title: "Bridge",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fbridge.mp4?alt=media&token=b6d84aae-4098-47dc-b4ec-0f2565de4639", 'camera_zoom': 3.0},
    {model: 'KF', title: "Climbing Stairs",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fclimbingstairs.mp4?alt=media&token=ccf577f8-142d-4d0a-82af-a426884dc62d", 'camera_zoom': 3.0},
    
    // too short
    // {title: "Counting",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fcounting.mp4?alt=media&token=57e3e703-4aa3-4603-930c-07ae529bc5b8"},
    
    {model: 'KF', title: "Crawling, Standing",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fcrawlingstanding.mp4?alt=media&token=1e5076a9-2237-49bf-a411-995ff01cceaa", 'camera_zoom': 3.0},
    
    // curling
    // {title: "Curling",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fcurling.mp4?alt=media&token=e2e88bd8-04af-49c0-80a1-ccd46c80c6fa"},
    {model: 'KF', title: "Dancing10",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing10.mp4?alt=media&token=098cba38-be2d-4c62-9ae5-9cc3befd82df", 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing3",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing3.mp4?alt=media&token=925f425e-c79e-4de6-9da0-0947e1b86161", 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing4",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing4.mp4?alt=media&token=209ac20a-ef85-452f-9f71-1f7a94f09023", 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing5",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing5.mp4?alt=media&token=286d6b04-f485-461f-aff1-3199a32d0eb7", 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing6",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing6.mp4?alt=media&token=87d57749-247e-47e8-ace5-db1d172f81bf", 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing7",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing7.mp4?alt=media&token=086c7d43-f864-4159-9ea3-8909f9ba9c96", 'camera_zoom': 3.0},
    {model: 'KF', title: "Dancing8",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing8.mp4?alt=media&token=1cbcd554-9e3e-4b48-ac58-d3665c2125b9", 'camera_zoom': 3.0},
    
    // too short
    // {title: "Dancing9",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdancing9.mp4?alt=media&token=24576257-ed4f-4399-8f13-b3ff00415862"},
    
    {model: 'KF', title: "Dodging1",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fdodging1.mp4?alt=media&token=f54e8504-1810-498e-a435-47d576bbaed0", 'camera_zoom': 3.0},
    {model: 'KF', title: "Forward Roll",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fforwardroll.mp4?alt=media&token=c8e9d398-6c12-4b24-802b-d9a59e623709", 'camera_zoom': 3.0},
    {model: 'KF', title: "Headshake Dance",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fheadshakedance.mp4?alt=media&token=b99958cc-68f7-488b-8e59-43c064878a5c", 'camera_zoom': 3.0},
    {model: 'KF', title: "Karate",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fkarate1.mp4?alt=media&token=3a8549a5-5c8f-4564-a198-228e34ad0c14", 'camera_zoom': 3.0},
    {model: 'KF', title: "Left Lifted",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fleflifted.mp4?alt=media&token=d126eeca-b746-4411-9f00-8dd8c2a77b21", 'camera_zoom': 3.0},
    {model: 'KF', title: "Leg in the Air",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Flegintheair.mp4?alt=media&token=601b9773-c804-4859-b1d0-3b5d71b045e4", 'camera_zoom': 3.0},
    
    // too short
    // {title: "Light Stirring",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Flightstirring.mp4?alt=media&token=c03c7d3b-b3d1-4072-b60c-9afc8af0c4f3"},
    
    {model: 'KF', title: "Limping",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Flimping.mp4?alt=media&token=57465c61-5ea5-40f4-ba45-3e8b6fd7db6e", 'camera_zoom': 3.0},
    {model: 'KF', title: "Matrix 1",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fmatrix1.mp4?alt=media&token=03daac9f-bf6e-4caa-b1ee-5bad6dca4a07", 'camera_zoom': 3.0},
    
    // Doesn't render well
    // {title: "Putting Shoes On",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fputtingshoeson.mp4?alt=media&token=7ee17983-193a-4198-8719-108983ca6cfa"},
    {model: 'KF', title: "River Dance",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Friverdance.mp4?alt=media&token=8e1c87cc-9202-44d7-9ce3-dbc684ec9e56", 'camera_zoom': 3.0},
    
    // Doesn't render well
    // {title: "Rolling Hands",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Frollinghands.mp4?alt=media&token=2cb44812-7e77-4b11-a9ca-5da3db99f595"},
    {model: 'KF', title: "Rolling Head",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Frollinghead.mp4?alt=media&token=433de652-5a0e-4f8e-9e43-d52c1beac45e", 'camera_zoom': 3.0},
    {model: 'KF', title: "Runing in Circle",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Frunningcircle.mp4?alt=media&token=03056975-8090-4230-8855-3db3c5c5e7c7", 'camera_zoom': 3.0},
    {model: 'KF', title: "Running in Circle 2",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Frunningcircle2.mp4?alt=media&token=4c3afd8b-74f8-4be1-b4de-8acf80087c8f", 'camera_zoom': 3.0},
    
    // too short
    // {title: "Shoulder Shake",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fshouldershake.mp4?alt=media&token=f672227c-d671-4ca4-bd0a-8579c999aedb"},
    
    {model: 'KF', title: "Sidestepping",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fsidestepping.mp4?alt=media&token=9acaf7a5-2942-48ac-931b-57d18743198b", 'camera_zoom': 3.0},
    {model: 'KF', title: "Spin 2",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fspin2.mp4?alt=media&token=c80ad665-8f33-462f-b4e8-5413f4747b0a", 'camera_zoom': 3.0},
    {model: 'KF', title: "Squirming Seated",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fsquirmingseated.mp4?alt=media&token=7090ca4d-6dfb-4fd4-9698-8fe4e97b7116", 'camera_zoom': 3.0},
    {model: 'KF', title: "Standing on One Leg",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fstandingoneleg.mp4?alt=media&token=1c606736-5bb4-4b0f-bbe4-0eb3949106b2", 'camera_zoom': 3.0},
    {model: 'KF', title: "Surrendering",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fsurrendering.mp4?alt=media&token=b58a6756-80c8-4aeb-8be1-f9ddf6262b05", 'camera_zoom': 3.0},
    {model: 'KF', title: "Swaying Walk",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fswayingwalk.mp4?alt=media&token=700e4122-96f8-4f46-881e-9931a15e24b9", 'camera_zoom': 3.0},
    {model: 'KF', title: "Swaying Walk 2",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fswayingwalk2.mp4?alt=media&token=020bb16e-52b0-4433-ad5a-f20e7e458ca3", 'camera_zoom': 3.0},
    {model: 'KF', title: "Swing and Throw",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fswingandthrow.mp4?alt=media&token=4f36884b-2e26-4587-81c4-4ab66e5e125a", 'camera_zoom': 3.0},
    {model: 'KF', title: "Touching Toes",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Ftouchingtoes.mp4?alt=media&token=822a9e0a-c836-4fd0-83f9-df280d521ff1", 'camera_zoom': 3.0},
    {model: 'KF', title: "Tripping",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Ftripping.mp4?alt=media&token=efd178a6-7512-4e36-8228-c477757edaa9", 'camera_zoom': 3.0},
    {model: 'KF', title: "Twirling 3",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Ftwirling3.mp4?alt=media&token=258d1e63-bb6d-47ed-a0d2-91db57f8bf89", 'camera_zoom': 3.0},
    {model: 'KF', title: "Unscrewing",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Funscrewing.mp4?alt=media&token=3722227b-2b4a-4e27-9d2b-e6662a93f359", 'camera_zoom': 3.0},
    {model: 'KF', title: "Walking 4",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fwalking4.mp4?alt=media&token=d9a41873-3c62-43b2-91ec-8714909dd9bd", 'camera_zoom': 3.0},
    {model: 'KF', title: "Walking In",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fwalkingin.mp4?alt=media&token=5c55c6f9-8a7d-42e5-a6ba-ab71cfa5564b", 'camera_zoom': 3.0},
    {model: 'KF', title: "Walking Turning",  url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/motions%2Fwalkingturning.mp4?alt=media&token=064c59d9-bb5b-4c38-ae04-ce8db9eccd4f", 'camera_zoom': 3.0},


    // ONLY MOTIONS BELOW THIS COMEMENT CAN USE 3D
    {model: '3D', title: 'Applause_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Applause_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Applause_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Applause_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Breakdance_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Breakdance_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Breakdance_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Breakdance_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Breakdance_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Breakdance_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Crawling', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Crawling.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Dancing_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Dancing_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Dancing_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Dancing_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Dancing_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Dancing_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Defending', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Defending.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Dodging', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Dodging.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Flying' , url:'//storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Flying.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Frontflip', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Frontflip.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Getting_attention', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Getting_attention.mp4', 'camera_zoom': 3.0},

    // Needs to be shortened
    // {model: 'KF', title: 'Hands_up', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Hands_up.mp4', 'camera_zoom': 3.0},
    

    {model: '3D', title: 'Handstand_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Handstand_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Handstand_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Handstand_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Handstand_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Handstand_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Handstand_4', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Handstand_4.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Handstand_5', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Handstand_5.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Jumping_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Jumping_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Jumping_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Jumping_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Jumping_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Jumping_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Kicking_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Kicking_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Kicking_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Kicking_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Kicking_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Kicking_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Kicking_4', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Kicking_4.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Looking_down_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Looking_down_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Looking_down_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Looking_down_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Lying_down', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Lying_down.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_4', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_4.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_5', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_5.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_6', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_6.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Punching_7', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Punching_7.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Sidekick', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Sidekick.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Somersault', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Somersault.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Stepping_back_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Stepping_back_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Stepping_back_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Stepping_back_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Stepping_back_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Stepping_back_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Stepping_forward_1', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Stepping_forward_1.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Stepping_forward_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Stepping_forward_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Stepping_forward_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Stepping_forward_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Streetfighting', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Streetfighting.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Throwing', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Throwing.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Turning_around', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Turning_around.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Turning_around_2', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Turning_around_2.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Turning_around_3', url: 'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Turning_around_3.mp4', 'camera_zoom': 3.0},
    {model: '3D', title: 'Waving' , url:'https://storage.googleapis.com/fastab-f08e9.appspot.com/00motion_inputs_prod/Waving.mp4', 'camera_zoom': 3.0},

];