import React, { useState, useEffect } from 'react';

const ProgressBar = ({ duration = 10000, onCompletion }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalDuration = duration / 100; // Divide the duration into 100 intervals
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        if (nextProgress >= 95) {
          clearInterval(interval); // Stop the interval when 95% is reached
          if (typeof onCompletion === 'function') {
            onCompletion(); // Call the onCompletion callback if provided
          }
          return prevProgress; // Keep the progress at 95%
        }
        return nextProgress;
      });
    }, intervalDuration);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [duration, onCompletion]);

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
      <div
        className="progress-pulse-restart h-2.5 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
