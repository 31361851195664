const GENERAL = {
  '️an ancient Greek man': 'greek_man',
  'an African tribal woman': 'tribal_woman',
  'a feudal Japanese samurai': 'japanese_samurai',
  '️a Viking woman warrior': 'viking_warrior_woman',
  'an Egyptian pharaoh': 'egyptian_pharaoh',
  // 'a medieval European knight woman': 'knight_woman',
  'a Native American chief': 'native_american_chief',
  'a Chinese imperial scholar woman': 'chinese_scholar_woman',
  'a Mayan priest': 'mayan_priest',
  'an Arabian merchant woman': 'arabian_merchant_woman',
  'a Renaissance Italian artist': 'italian_artist',
  'a Russian Tsarina': 'russian_tsarina',
  'an American astronaut': 'american_astronaut',
  'a British prime minister woman': 'british_prime_minister_woman',
  'a Brazilian soccer player': 'brazilian_soccer_player',
  'an Indian Bollywood actress': 'bollywood_actress',
  // 'a Canadian mountie': 'canadian_mountie',
  '‍an Australian surfer woman': 'australian_surfer_woman',
  '‍a French chef': 'french_chef',
  // 'a Japanese robotics engineer woman': 'robotics_engineer_woman',
  'an elven archer': 'elven_archer',
  '️a dwarven warrior woman': 'dwarven_warrior_woman',
  'a futuristic soldier': 'futuristic_soldier',
  'a space pilot woman': 'space_pilot_woman',
  'a steampunk inventor': 'steampunk_inventor',
  '‍a fantasy elf woman': 'fantasy_elf_woman',
};


const FACE = {
  greek_man: ['piercing eyes', 'long brown hair', 'thick beard', 'bronzed skin', 'focused gaze'],
  tribal_woman: ['face paint', 'short dark hair', 'intense eyes', 'earrings', 'nose piercing'],
  japanese_samurai: ['topknot', 'intimidating stare', 'scarred face', 'stern expression', 'clean-shaven'],
  viking_warrior_woman: ['braided hair', 'blue eyes', 'battle scar', 'fierce look', 'face paint'],
  egyptian_pharaoh: ['golden headdress', 'black eyeliner', 'jeweled necklace', 'majestic gaze', 'shaved head'],
  knight_woman: ['helmet', 'determined eyes', 'scar across cheek', 'short blond hair', 'stern look'],
  native_american_chief: ['feather headdress', 'long black hair', 'painted face', 'penetrating gaze', 'earrings'],
  chinese_scholar_woman: ['bun with sticks', 'gentle eyes', 'wisdom lines', 'pale complexion', 'thin eyebrows'],
  mayan_priest: ['feathered headpiece', 'ritual tattoos', 'dark intense eyes', 'long earlobes', 'ceremonial paint'],
  arabian_merchant_woman: ['veil', 'bright eyes', 'henna tattoos', 'gold nose ring', 'luxurious headscarf'],
  italian_artist: ['flowing hair', 'thoughtful eyes', 'paint smudges', 'beret', 'piercing gaze'],
  russian_tsarina: ['elegant headdress', 'regal expression', 'pale skin', 'jeweled tiara', 'stern look'],
  american_astronaut: ['buzz cut', 'focused eyes', 'visor tan lines', 'clean-shaven', 'determined expression'],
  british_prime_minister_woman: ['styled hair', 'sharp eyes', 'glasses', 'professional makeup', 'confident look'],
  brazilian_soccer_player: ['short curly hair', 'exuberant expression', 'athletic headband', 'vivid eyes', 'sweatband'],
  bollywood_actress: ['luxurious hair', 'expressive eyes', 'glamorous makeup', 'bindi', 'elegant earrings'],
  canadian_mountie: ['neat hair', 'observant eyes', 'mountie hat', 'strong jawline', 'authoritative look'],
  australian_surfer_woman: ['sun-bleached hair', 'relaxed expression', 'sun-kissed skin', 'surfing goggles', 'cheerful smile'],
  french_chef: ['chef hat', 'jovial expression', 'moustache', 'flour on face', 'warm eyes'],
  robotics_engineer_woman: ['practical hair', 'intelligent glasses', 'focused eyes', 'clean lab coat', 'inquisitive look'],
  elven_archer: ['silver hair', 'sharp ears', 'mystical tattoo', 'piercing gaze', 'ethereal beauty'],
  dwarven_warrior_woman: ['braided beard', 'stout expression', 'battle helmet', 'fiery eyes', 'stoic demeanor'],
  futuristic_soldier: ['cybernetic eye', 'buzzed hair', 'tactical headset', 'scarred visage', 'resolute expression'],
  space_pilot_woman: ['flight helmet', 'radiant eyes', 'communication gear', 'space freckles', 'confident smile'],
  steampunk_inventor: ['goggles', 'wild hair', 'oil smudges', 'focused eyes', 'eccentric mustache'],
  fantasy_elf_woman: ['long pointed ears', 'enchanting eyes', 'leaf tiara', 'luminous skin', 'serene expression'],
};

const DETAIL = {
  greek_man: ['white toga', 'red toga', 'gold belt', 'sandals', 'armbands'],
  tribal_woman: ['leather dress', 'bead necklace', 'animal skin cape', 'barefoot', 'arm cuffs'],
  japanese_samurai: ['samurai armor', 'kimono', 'hakama pants', 'wooden sandals', 'katana belt'],
  viking_warrior_woman: ['chainmail', 'leather armor', 'fur cloak', 'boots', 'shield'],
  egyptian_pharaoh: ['white linen kilt', 'gold collar', 'ceremonial robe', 'royal scepter', 'sandals'],
  knight_woman: ['chainmail', 'plate armor', 'surcoat', 'gauntlets', 'sword belt'],
  native_american_chief: ['buckskin tunic', 'feathered cape', 'beaded belt', 'moccasins', 'war bonnet'],
  chinese_scholar_woman: ['silk robe', 'embroidered dress', 'beaded shawl', 'slippers', 'fan'],
  mayan_priest: ['ritual robe', 'jade belt', 'feathered sandals', 'gold armbands', 'ceremonial staff'],
  arabian_merchant_woman: ['flowing robe', 'silk sash', 'beaded vest', 'flat shoes', 'jeweled bracelet'],
  italian_artist: ['paint-splattered smock', 'linen shirt', 'soft pants', 'leather shoes', 'beret'],
  russian_tsarina: ['royal gown', 'fur cloak', 'diamond scepter', 'elegant slippers', 'coronation crown'],
  american_astronaut: ['space suit', 'mission patch', 'utility belt', 'space boots', 'helmet'],
  british_prime_minister_woman: ['formal suit', 'pearl necklace', 'sensible shoes', 'brooch', 'ceremonial sash'],
  brazilian_soccer_player: ['soccer jersey', 'shorts', 'soccer cleats', 'shin guards', 'team cap'],
  bollywood_actress: ['glamorous sari', 'sparkling bangles', 'embellished sandals', 'elegant stole', 'ornate belt'],
  canadian_mountie: ['red serge', 'riding breeches', 'high brown boots', 'campaign hat', 'leather belt'],
  australian_surfer_woman: ['wetsuit', 'surf shorts', 'rash guard', 'ankle leash', 'surfboard under arm'],
  french_chef: ['chef coat', 'checkered pants', 'apron', 'chef shoes', 'toque blanche'],
  robotics_engineer_woman: ['lab coat', 'utility belt', 'protective glasses', 'work boots', 'tool pouch'],
  elven_archer: ['leather tunic', 'quiver', 'elven cloak', 'soft boots', 'arm guards'],
  dwarven_warrior_woman: ['battle armor', 'forged hammer', 'leather belt', 'heavy boots', 'helmet'],
  futuristic_soldier: ['combat armor', 'holstered blaster', 'utility harness', 'tactical boots', 'visor helmet'],
  space_pilot_woman: ['flight suit', 'aviator jacket', 'pilot gloves', 'flight boots', 'navigation wrist device'],
  steampunk_inventor: ['gadget-laden vest', 'tweed trousers', 'goggled cap', 'leather boots', 'tool belt'],
  fantasy_elf_woman: ['forest dress', 'crystal necklace', 'light armor', 'elfin sandals', 'magic focus bracelet'],
};

export {
  GENERAL,
  FACE,
  DETAIL,
}