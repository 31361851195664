import React, { useState } from 'react';

const DescriptionInput = ({ onDescriptionComplete, backButton, specialState }) => {
  const [description, setDescription] = useState('');

  const handleSubmit = () => {
    if (description.trim() !== '') {
      onDescriptionComplete(description);
    } else {
      console.log('Submit failed: empty or only whitespace'); // Check why submit might fail
    }
  };

  const handleInputChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
  };

  // Conditionally set the header and placeholder based on the specialState
  const headerText = specialState === 'pookie' 
    ? "Describe the person and their outfit" 
    : "Describe the person";
  const placeholderText = specialState === 'pookie' 
    ? "A woman in her 20s with black wavy hair, wearing a Chanel dress with a Birkin bag" 
    : "A man in his 20s with black, wavy hair";

  return (
    <div className="upload-container max-w-4xl mx-auto space-y-4">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-800">{headerText}</h2>
      </div>
      
      <textarea
        className="form-textarea mt-1 block w-full p-2 rounded font-mono border border-gray-300 shadow-sm"
        placeholder={placeholderText}
        value={description}
        onChange={handleInputChange}
        style={{ minHeight: '100px' }}
      />

      <div className="flex justify-between">
        <button 
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
          onClick={backButton}>
          Back
        </button>
        <button
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${description.trim() === '' ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
          onClick={handleSubmit}
          disabled={description.trim() === ''}>
          Next
        </button>
      </div>
    </div>
  );
};

export default DescriptionInput;