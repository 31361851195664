import React from 'react';
import Modal from '../Components/Modal.jsx';
import { openPosesArray } from '../AssetPreloads/openPosesArray';

const AllPosesModal = ({ isOpen, onClose, onPoseSelect }) => {
  if (!isOpen) return null;

  const handlePoseClick = (pose) => {
    if (onPoseSelect) {
      onPoseSelect(pose);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Choose a Pose" size="large">
      <div className="modal-content max-h-[80vh] overflow-y-auto">
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-4 mt-4">
          {openPosesArray.filter(pose => pose.show_pose).map((pose, index) => (
            <div key={index} className="cursor-pointer" onClick={() => handlePoseClick(pose)}>
              <div className="flex flex-col items-center p-2">
                <img src={pose.square_image} alt={`Pose ${index + 1}`} className="w-full h-auto rounded-lg" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AllPosesModal;