import React from 'react';
import { useUser } from '../contexts/UserContext';
import DropdownMenu from './DropdownMenu.jsx';
import { useParams } from 'react-router-dom';

export default function MenuBar() {
    const { currentUser } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const { specialState } = useParams();
    const discordLink = "https://discord.gg/8Ju3mHesR5";
    const appStoreLink = "https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956";

    return (
        <div className="w-full h-10 flex items-center justify-between px-4 border-b border-gray-900 fixed top-0 z-50 bg-gray-900">
            <div className="flex items-center">
                <a 
                    href={appStoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-center items-center"
                >
                    <img 
                        src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" 
                        alt="Download on the App Store" 
                        className="h-8 transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer"
                    />
                </a>
            </div>
            <div className="flex items-center">
                <a href={discordLink} target="_blank" rel="noopener noreferrer" className="mr-4">
                    <button className="bg-[#5865F2] text-white font-semibold px-2 py-1 rounded inline-flex items-center hover:">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/discord_white.png?alt=media&token=bc96f6d2-ebd5-44ba-87fc-4db30b2258d9" className="mr-2 h-4 w-5" alt="Discord logo" />
                        Discord
                    </button>
                </a>
                <DropdownMenu username={username} specialState={specialState}/>
            </div>
        </div>
    );
}