import React, { useState, useEffect, useRef } from 'react';
import Modal from '../Components/Modal.jsx';
import { motionInputsArray, suggestedMotionsArray } from '../AssetPreloads/motionInputsArray';

const AllMotionsModal = ({ isOpen, onClose, onMotionSelect }) => {
  const [loadingStates, setLoadingStates] = useState(motionInputsArray.map(() => true));
  const videoRefs = useRef([]);

  useEffect(() => {
    if (isOpen) {
      setLoadingStates(motionInputsArray.map(() => true));
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleMouseEnter = (event) => {
    const video = event.currentTarget;
    if (video.readyState >= 3) {
      video.play();
    }
  };

  const handleMouseLeave = (event) => {
    const video = event.currentTarget;
    video.pause();
  };

  const handleMotionClick = (motion) => {
    onMotionSelect(motion); // Set the motion as soon as it's clicked
    onClose(); // Close the modal after selection
  };

  const handleVideoLoaded = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Choose a Motion" size="large">
      <div className="modal-content max-h-[80vh] overflow-y-auto">
        <div className='bg-purple-200 rounded-lg p-2'>
        <p className='text-lg text-gray-900 font-semibold mt-4  '>Suggested Motions 🏃‍♂️</p>
        <MotionListView motionArray={suggestedMotionsArray} handleMotionClick={handleMotionClick} /> 
        </div>
        <p className='text-lg text-gray-900 font-semibold mt-4  '>All Motions</p>
        <MotionListView motionArray={motionInputsArray} handleMotionClick={handleMotionClick} /> 
      </div>
    </Modal>
  );
};

const MotionListView = ({motionArray, handleMotionClick}) => {
  const [loadingStates, setLoadingStates] = useState(motionArray.map(() => true));
  const videoRefs = useRef([]);

  const handleMouseEnter = (event) => {
    const video = event.currentTarget;
    if (video.readyState >= 3) {
      video.play();
    }
  };

  const handleMouseLeave = (event) => {
    const video = event.currentTarget;
    video.pause();
  };

  const handleVideoLoaded = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 mt-2">

      {motionArray.map((motion, index) => (
        <div
          key={index}
          className={`cursor-pointer m-2 hover:ring-4 hover:ring-green-500 rounded-lg`}
          onClick={() => handleMotionClick(motion)}
        >
          <div className="flex flex-col items-center p-1 h-full">
            <div className={`${loadingStates[index] ? 'animate-pulse' : ''} bg-gray-200 rounded-md py-2 px-4 w-full h-full`}>
              <p className='text-gray-700 font-semibold py-2'>{motion.title.replace('_', ' ')}</p>
              {loadingStates[index] && (
                <div className="flex justify-center items-center w-full h-40">
                  <div className="text-gray-400 font-semibold text-sm">Loading...</div>
                </div>
              )}
              <div className="relative">
                <video
                  className={`w-full h-auto rounded-md ${loadingStates[index] ? 'hidden' : 'block'}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onCanPlay={() => handleVideoLoaded(index)}
                  muted
                  ref={(el) => (videoRefs.current[index] = el)}
                >
                  <source src={motion.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {!loadingStates[index] && (
                  <div className="absolute top-1 left-1 bg-gray-800 text-white text-xs font-semibold rounded-full px-2 py-1">
                    {videoRefs.current[index]?.duration?.toFixed(1)}s
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

}

export default AllMotionsModal;
