import React from 'react';

const ModeSelector = ({ mode, setMode, firstModeLabel, secondModeLabel }) => {
  const baseStyle = 'w-full py-2 cursor-pointer transition-all duration-200 text-left text-sm uppercase text-left';
  const selectedStyle = 'font-bold border-b-4 border-blue-500 text-blue-500';
  const unselectedStyle = 'border-b-2 border-gray-300 hover:border-gray-400 text-gray-500';

  return (
    <div className="flex w-full space-x-1">
      <div
        className={`${baseStyle} ${mode === firstModeLabel ? selectedStyle : unselectedStyle}`}
        onClick={() => setMode(firstModeLabel)}
      >
        {firstModeLabel}
      </div>
      <div
        className={`${baseStyle} ${mode === secondModeLabel ? selectedStyle : unselectedStyle}`}
        onClick={() => setMode(secondModeLabel)}
      >
        {secondModeLabel}
      </div>
    </div>
  );
};

export default ModeSelector;
