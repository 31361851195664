import React, { useState, useEffect } from 'react';


function PromptInput({
  promptPrefix,
  prompt,
  setPrompt,
  enterPressed,
  enterToSubmit, 
  examples,
  lengthLimits,
  minLength,
  maxLength,
  characterSelected,
}) {


  const [placeholder, setPlaceholder] = useState('');
  const [promptIndex, setPromptIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [promptAreaFocused, setPromptAreaFocused] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [promptValid, setPromptValid] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {

    if (!promptAreaFocused) {
      const typeSpeed = isDeleting ? 10 : 15;
      let timeout;

      if (!isDeleting && charIndex < examples[promptIndex].length) {
        timeout = setTimeout(() => {
          setPlaceholder((val) => val + examples[promptIndex][charIndex]);
          setCharIndex(charIndex + 1);
        }, typeSpeed);
      } else if (isDeleting && charIndex > 0) {
        timeout = setTimeout(() => {
          setPlaceholder((val) => val.substring(0, val.length - 1));
          setCharIndex(charIndex - 1);
        }, typeSpeed);
      } else if (isDeleting) {
        setIsDeleting(false);
        setPromptIndex((prevIndex) => (prevIndex + 1) % examples.length);
        setCharIndex(0);
        setPlaceholder('');
      } else {
        // Pause before starting to delete the placeholder text
        timeout = setTimeout(() => setIsDeleting(true), 2000);
      }
      return () => clearTimeout(timeout);

    }
  }, [charIndex, isDeleting, promptIndex, promptAreaFocused]);

  const handleChange = (e) => {
    setPrompt(e.target.value);

    if (lengthLimits) {
      if (e.target.value.length < minLength) {
        setErrorMsg("That's too short. A good prompt is at least 20 characters. Browse the Presets for inspiration.")
        setPromptValid(false)
      }
      else if (e.target.value.length > maxLength) {
        setErrorMsg("That's too long! Don't worry you can add more details later.")
        setPromptValid(false)
      }
      else {
        setErrorMsg('Perfect!')
        setPromptValid(true)
      }
    }


  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && enterPressed) {
      console.log('Enter pressed!');
      enterPressed();
      event.preventDefault();

    }
  };

  return (
    <div className="mt-1 flex flex-col justify-between">
      <div className='w-full p-2 rounded-md font-mono border border-gray-300 shadow-sm min-h-24 bg-gray-100'>
      <p className='bg-gray-100'><span className='bg-blue-500 px-2 py-1 rounded-full text-white text-sm'>{`${promptPrefix}`}</span>...</p>
      <textarea
        className="form-textarea mt-1 block w-full rounded-md font-mono min-h-16 bg-gray-100 px-2 text-sm focus:outline-none"
        placeholder={placeholder || "Type something..."}
        value={prompt}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={!characterSelected}
      ></textarea>
      </div>
      {
        enterToSubmit && (
          <div className='flex flex-row-reverse'>
            {/*<div className="flex flex-row-reverse text-xs text-gray-500 py-1">
              Press Enter to generate
            </div>*/}
            <button
              onClick={() => enterPressed()} 
              className='bg-purple-500 text-white my-2 p-2 hover:bg-green-500 disabled:bg-gray-400 rounded font-bold'
              disabled={prompt == ''}>
              Generate</button>
          </div>
        )
      }
      <p className={`text-xs py-1 ${(promptValid) ? 'text-green-500' : 'text-gray-500'}`}>{(lengthLimits && prompt.length > 0) ? errorMsg : null}</p>

    </div>
  );
}

export default PromptInput;