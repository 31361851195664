import React, { useState, useEffect } from 'react';

function AudioClips({ audioClips, addToComposer }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [audioElements, setAudioElements] = useState({}); // Store audio elements by clip index
    const clipsPerPage = 3;
    const totalPages = Math.ceil(audioClips.length / clipsPerPage);

    useEffect(() => {
        const newAudioElements = {};
        audioClips.forEach((clip, index) => {
            const audio = new Audio(clip.output_images[0]);
            audio.onloadedmetadata = () => {
                // Update audio element with loaded metadata
                newAudioElements[index] = audio;
                setAudioElements(prev => ({ ...prev, ...newAudioElements }));
            };
        });

        // Cleanup function to revoke object URLs and remove event listeners
        return () => {
            Object.values(newAudioElements).forEach(audio => {
                audio.src = ""; // Clean up the audio source
            });
        };
    }, [audioClips]); // Dependency on audioClips to refresh when they change

    const handleDragStart = (event, clip, index) => {
        // Setting drag data with asset URL and duration
        const audio = audioElements[index];
        if (audio) {
            event.dataTransfer.setData('text/asset-url', clip.output_images[0]);
            event.dataTransfer.setData('text/asset-duration', audio.duration.toString());
        }
    };

    const handleAddToComposer = (index) => {
        const audio = audioElements[index];
        if (audio) {
            addToComposer(audio.src, audio.duration);
        }
    };

    const currentAudioClips = audioClips.slice(
        (currentPage - 1) * clipsPerPage,
        currentPage * clipsPerPage
    );

    return (
        <div className="h-1/2 p-4 overflow-y-auto bg-gray-100 mx-2">
            <h2 className="text-xl text-gray-800 font-semibold mb-4">Audio Clips</h2>
            {audioClips.length > 0 ? (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        {currentAudioClips.map((clip, index) => (
                            <div key={clip.id} 
                                 className="bg-white p-2 rounded shadow flex flex-col items-center justify-center"
                                 draggable
                                 onDragStart={(event) => handleDragStart(event, clip, index + ((currentPage - 1) * clipsPerPage))}>
                                <audio controls className="w-full" src={clip.output_images[0]}>
                                    Your browser does not support the audio tag.
                                </audio>
                                <div className="mt-2 text-sm text-center">{clip.prompt || "No description"}</div>
                                <button className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                        onClick={() => handleAddToComposer(index + ((currentPage - 1) * clipsPerPage))}>Add to Composer</button>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center items-center space-x-4">
                        {currentPage > 1 && (
                            <button onClick={() => setCurrentPage(currentPage - 1)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Previous
                            </button>
                        )}
                        <span>Page {currentPage} of {totalPages}</span>
                        {currentPage < totalPages && (
                            <button onClick={() => setCurrentPage(currentPage + 1)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Next
                            </button>
                        )}
                    </div>
                </>
            ) : (
                <div className="text-center">
                    <p className="text-gray-800">Audio clips created in Playground will load here</p>
                </div>
            )}
        </div>
    );
}

export default AudioClips;
