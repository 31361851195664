import React, { useState } from "react";
import CharacterSelector from './CharacterSelector.jsx';
import { db } from '../Firebase.js';
import {
  setDoc,
  doc,
} from 'firebase/firestore';
import { hpPreloadAssetArray } from '../AssetPreloads/HpPreloadAssetArray.js';
import PromptExplainModal from '../Components/PromptExplainModal.jsx';
import PromptInputGuided from '../Components/PromptInputGuided.jsx';
import MotionStillInputPromptInput from '../Components/MotionStillInputPromptInput.jsx';
import AllMotionsModal from './AllMotionsModal';
import { openPosesArray } from '../AssetPreloads/openPosesArray';
import { useUser } from '../contexts/UserContext';

const negative_prompt = '((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs))), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w';

const isAllFromPreloadData = (finetune_predictions, preloadDataArray) => {
  return finetune_predictions.every(prediction => 
    preloadDataArray.some(preloadItem => preloadItem.prediction_id === prediction.prediction_id)
  );
};

function InputForm({
    user,
    playgroundState,
  }) {
    const {idToken} = useUser();
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [promptOk, setPromptOk] = useState(false);
    const [errors, setErrors] = useState([]);
    const [character, setCharacter] = useState(null);
    const [selectedPose, setSelectedPose] = useState(null);
    const [promptUpdatedManually, setPromptUpdatedManually] = useState(false);

    // For motion still creation -- requires a background and costume
    const [backgroundPrompt, setBackgroundPrompt] = useState('');
    const [costumePrompt, setCostumePrompt] = useState('');

    // For motion selection
    const [isAllMotionsModalOpen, setIsAllMotionsModalOpen] = useState(false);

    const [selectedMotion, setSelectedMotion] = useState(null)
    // const [selectedMotionUrl, setSelectedMotionUrl] = useState('');
    // const [selectedMotionName, setSelectedMotionName] = useState('');

    // Function to handle modal opening
    const openModal = () => {
      setIsModalOpen(true);
    }

    // Function to handle modal closing
    const closeModal = () => {
      setIsModalOpen(false);
    }

    // Update this function to set promptUpdatedManually to true
    const handlePromptChange = (newPrompt) => {
      setPrompt(newPrompt);
      setPromptUpdatedManually(true); // Set to true when prompt is manually updated
    };

    const createProcessingDocument = async (user, model_url, reference_face_url, prompt, negative_prompt, character_name, character_type) => {
      // Prepare the document data with status set to 'PROCESSING'
      const predictionId = `${user}-${new Date().toISOString()}`;
      const assetType = character_type === "Voice" 
        ? 'AUDIO' 
        : (character_type === "Motion" ? 'VIDEO' : 'PHOTO');
      const processingDocument = {
        prediction_id: predictionId,
        model_url: model_url,
        reference_face_url: reference_face_url,
        output_images: [],
        prompt: prompt.replace('TOK', character_name),
        negative_prompt: negative_prompt,
        status: 'PROCESSING',
        asset_type: assetType,
        timestamp: new Date().toISOString(),
      };

      const photoDocRef = doc(db, "character-users", user, "finetune_predictions", predictionId);
      await setDoc(photoDocRef, processingDocument);

      return predictionId; // This ID will be sent to the backend to update the document upon completion
    };

    const makePromptPrefix = () => {
      if (character === null) return null;

      if (character.character_type === "Voice")
        return "Voice saying";      
      else if (character.character_type === "Freestyle")
        return "A film scene of";
      else
        return `A film scene of ${character.character_name}`;
    };

    // Modify the onRun function to include the createProcessingDocument call
    const onRun = async () => {
      // Existing error handling and data preparation code...

      let fullPrompt;
      if (character.character_type === "Motion")
        fullPrompt = `A film scene of ${character.character_name} wearing ${costumePrompt}, background is ${backgroundPrompt}`;
      else
        fullPrompt = `${makePromptPrefix()} ${prompt}`;

      // Create the processing document and get the prediction ID
      const prediction_id = await createProcessingDocument(
        user, character.model_url, character.reference_face_url, fullPrompt, 
        negative_prompt, character.character_name, character.character_type
      );


      // Determine the endpoint based on whether a motion URL is selected
      let endpoint;
      let eventName;

      if (character.character_type === "Motion") {
        if (selectedMotion.model === "3D") {
          endpoint = "https://motion-branch-ai-apps-backend-c0d7311a9301.herokuapp.com/run_3D_motion";
          eventName = "3D-motion";
        } else {
          endpoint = 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com/keyframe_motion';
          eventName = 'keyframe-motion';
        }
      } else {
        endpoint = 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com/create_character_photo';
        eventName = 'run-playground-success';
      }

      // Pass t-pose for motion; otherwise only pass pose if specified
      let poseParam;
      let imageType;

      if (character.character_type === "Motion") {
        poseParam = openPosesArray[10].padded_image; // t-shaped pose
        imageType = "character_weights_motion";
      } else if (selectedPose !== null) {
        poseParam = selectedPose.padded_image || ""; // If selectedPose is not null but padded_image is undefined, default to ""
        imageType = "character_weights_pose";
      } else {
        poseParam = ""; // Default value for poseParam when no other conditions are met
        imageType = "character_weights"; // Default value for imageType when no other conditions are met
      }

      const data = {
        user: user,
        model_url: character.model_url,
        version: character.version,
        weights: character.weights ?? null,
        reference_face_url: character.reference_face_url,
        prompt: character.character_type === "Motion" ? "" : fullPrompt,
        negative_prompt: negative_prompt,
        character_name: character.character_name,
        character_type: character.character_type,
        character_details: character.character_details,
        selected_pose: poseParam,
        image_type: imageType,
        prediction_id: prediction_id,
        event_name: eventName,
        motion_url: selectedMotion?.url ?? null,
        outfit_description: costumePrompt,
        background_description: backgroundPrompt ?? null,

        // motion
        "motion": {
          "name": selectedMotion?.title ?? null,
          "folder": selectedMotion?.title ?? null,
          "camera_z": selectedMotion?.camera_zoom ?? null
        },
        // Pass with this name as well for motion prompt
        "clothes_prompt": costumePrompt,
        "face_url": character.reference_face_url,
        "lora_url": character.weights ?? null
      };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const responseBody = await response.json();
        console.log(`Successfully sent request. Received data back: ${responseBody}. Redirecting`);
      } else {
        console.error('Failed to start experiment');
      }
    };

    const noPredictions = playgroundState?.finetune_predictions?.length === 0 || isAllFromPreloadData(playgroundState.finetune_predictions, hpPreloadAssetArray);
    const characterSelected = character !== null;
    const highlightClasses = "glow";
    const applyGlow = (noPredictions && characterSelected) ? highlightClasses : '';

    const promptPrefix = makePromptPrefix();
    let examples = [];
    if (character !== null){
      if (character.character_type === "Voice") {
        examples = ["Eureka! I have a brilliant idea", "My heart is yours forever", "Come in to ye olde pub and enjoy a brew"];
      } else if (character.character_type === "Freestyle") {
        examples = ["a grassy meadow", "a post-apocalyptic city", "a futuristic martian colony"];
      } else {
        examples = ["walking on the moon", "riding a horse", "eating a sandwich"];
      }
    }

    return (
      <div className="p-4">
        <label className="block text-gray-900 text-xl mb-2 pl-2 font-bold" htmlFor="image-upload">🎬 Create a scene</label>

        <CharacterSelector
          customCharacters={playgroundState.custom_models}
          character={character}
          setCharacter={setCharacter}
          setPromptUpdatedManually={setPromptUpdatedManually}
          setPrompt={setPrompt}
          playgroundState={playgroundState}
          setSelectedPose={setSelectedPose}
          setIsAllMotionsModalOpen={setIsAllMotionsModalOpen}
        />

        {/* Text Field for Prompt */}
        {characterSelected && (character.character_type !== "Motion") && (
          <div className='mt-4 p-2 rounded-lg'>
            <div className="block mt-4">
              <div className='flex flex-row'> 
                <div className='w-1/2'>
                  <span className="text-gray-600 text-md mb-2 font-bold">{character.character_type === "Voice" ? "Dialogue text" : "Prompt"}</span>
                </div>
                <div className='w-1/2 text-right'>
                  {character.character_type !== "Voice" && (
                    <span className="text-blue-500 hover:text-blue-700 cursor-pointer text-xs" onClick={openModal}>What is this?</span>
                  )}                
                </div>
              </div>
              <PromptInputGuided
                prompt={prompt} 
                setPrompt={handlePromptChange} 
                setPromptOk={setPromptOk} 
                promptPrefix={promptPrefix} 
                examples={examples}
                characterSelected={characterSelected}
              />
            </div>
          </div>
        )}

        {/* Show motion still creation */}
        {characterSelected && character.character_type === "Motion" && (
          <div className='mt-4 p-2 rounded-lg'>
            <div className='flex flex-row'> 
              <div className='w-1/2'>
                <span className="text-gray-600 text-md mb-2 font-bold">{"Motion Clip"}</span>
              </div>
            </div>
            <p className='text-gray-600 text-xs'>
              Choose a motion, describe the outfit, describe the background
            </p>


<div className='mt-4 flex flex-col w-full'>
  {selectedMotion ?  (
    <div key={selectedMotion.url} className="mt-4 flex items-center">
      <video className="h-16 rounded-md" autoPlay loop muted>
        <source src={selectedMotion.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button 
        className="bg-gray-300 text-gray-700 hover:bg-gray-400 p-2 rounded font-bold h-16 ml-2 flex justify-center items-center w-full"
        onClick={() => setIsAllMotionsModalOpen(true)}
      >
        <div className='inline-block text-left'>
          <p className='text-xs font-medium'>{`Selected: ${selectedMotion.title}`}</p>
          <p className='text-sm'>Choose a different motion</p>
        </div>
      </button>
    </div>
  ) :
  (
  <div className='flex justify-between items-center w-full'>
    <button 
      className={`bg-gray-300 text-gray-700 hover:bg-gray-400 p-2 rounded font-bold ${selectedMotion ? 'w-1/2' : 'w-full'}`}
      onClick={() => setIsAllMotionsModalOpen(true)}
    >
      {selectedMotion ? 'Choose a Different Motion' : 'Select Motion'}
    </button>
  </div>
  )


}
</div>



{/*              <div className='mt-4 flex justify-between items-center w-full'>
                <button 
                  className='bg-gray-300 text-gray-700 hover:bg-gray-400 p-2 rounded w-full font-bold'
                  onClick={() => setIsAllMotionsModalOpen(true)}
                >
                  Select Motion
                </button>
              </div>
            {selectedMotionUrl && (
              <div key={selectedMotionUrl} className="mt-4">
                <span className="text-gray-600 text-md mb-2 font-bold">Selected Motion:</span>
                <video className="w-full h-auto rounded-md mt-2" controls>
                  <source src={selectedMotionUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}*/}
            <MotionStillInputPromptInput
              backgroundPrompt={backgroundPrompt}
              setBackgroundPrompt={setBackgroundPrompt}
              costumePrompt={costumePrompt}
              setCostumePrompt={setCostumePrompt}
              characterSelected={characterSelected}
              characterName={character.character_name}
            />
          </div>
        )}

        {/* Run Button and Errors Display */}
        {characterSelected && (
          <div className='text-right p-2'>
            <button 
              className='bg-black disabled:bg-gray-600 hover:bg-green-500 text-white p-2 rounded w-full'
              onClick={onRun}
              disabled={!characterSelected || (character.character_type == "Motion" && selectedMotion == null)}
            >
              Run
            </button>
            <div className='text-red-500 text-xs text-left my-2'>
              {errors.map(e => (<p key={e}>{e}</p>))}
            </div>
          </div>
        )}

        <PromptExplainModal isOpen={isModalOpen} closeModal={closeModal} />
        <AllMotionsModal 
          isOpen={isAllMotionsModalOpen} 
          onClose={() => setIsAllMotionsModalOpen(false)} 
          onMotionSelect={(motion) => {
            setSelectedMotion(motion)
            setIsAllMotionsModalOpen(false);
          }}
        />
      </div>
    );
  };

export default InputForm;
