import React from 'react';

export default function Modal({ isOpen, handleClose, children, title, size }){
  if (!isOpen) return null;

  // Define modal size classes
  const sizeClasses = {
    small: 'w-11/12 sm:w-2/3 md:w-1/2',
    medium: 'w-11/12 sm:w-3/4 md:w-2/3',
    large: 'w-11/12 sm:w-4/5 md:w-3/4',
    extraLarge: 'w-11/12 sm:w-full md:w-4/5'
  };

  const modalSizeClass = sizeClasses[size] || sizeClasses.medium; // Default to medium if no size is specified

  return (
    <div
  className="fixed inset-0 bg-gray-800 bg-opacity-80 overflow-y-auto h-full w-full z-50 flex items-center justify-center p-4"
  id="my-modal"
  onClick={handleClose}
>
  <div
    className={`relative mx-auto px-5 border shadow-lg rounded-md bg-white ${modalSizeClass} max-h-full overflow-y-auto`}
    style={{ maxHeight: 'calc(100vh - 2rem)' }} // Ensures modal doesn't exceed viewport height, accounting for padding
    onClick={(e) => e.stopPropagation()}
  >
    <button
      onClick={handleClose}
      className="absolute top-0 right-0 mt-2 mr-2 bg-transparent text-gray-900 font-semibold rounded p-1 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
    >
      <span aria-hidden="true">&times;</span>
    </button>

    <div className="text-left py-2">
      {title && <h3 className="text-lg font-bold">{title}</h3>}
      <div className="my-4">{children}</div>
    </div>
  </div>
</div>

  );
};
