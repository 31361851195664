import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function HpLandingPage() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // This function checks the width of the screen and updates the isMobile state
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // 768px is typically the width for mobile devices
    }

    // Call the function on component mount
    handleResize();

    // Add event listener to listen for screen resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100">
      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-4">
            <h1 className="text-5xl md:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 to-red-400 leading-none mb-6 py-2">Eggnog</h1>
            <p className="text-xl md:text-2xl font-light text-gray-300 max-w-2xl mx-auto leading-relaxed">Bring Harry Potter fanfiction to life with AI video</p>
          </header>
        <main>
          <div className='flex items-center justify-center p-2 mb-8'>
            <a href="/playground/hp" target="_blank" rel="noopener noreferrer">
              <button className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-semibold py-3 px-8 rounded-lg transition-all duration-300 ease-in-out shadow-lg">Start wizarding for free</button>
            </a>
          </div>
          <section className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-lg backdrop-filter backdrop-blur-md border border-gray-700">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Use preloaded characters</h3>
                  <p className="text-gray-300 text-center">All your favorites wizards</p>
                  <div className="grid grid-cols-2 gap-4">
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/hp_landing_0.jpg?alt=media&token=0447d7fa-9f68-4a12-a6f9-0c8dcd81e649" alt="Character 1" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/hp_landing_1.jpg?alt=media&token=10598226-d096-4e40-8824-fa9ca9288ac5" alt="Character 2" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/hp_landing_2.jpg?alt=media&token=ed461ec7-ded7-4c6b-b6f4-e3ea1a377fc0" alt="Character 4" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/hp_landing_3.jpg?alt=media&token=82bc3cab-62ef-4a00-bbbc-32e7faeb816b" alt="Character 3" className="w-28 h-28 rounded-lg"/>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Storyboard</h3>
                  <p className="text-gray-300 text-center">Drop in your characters</p>
                  <div className="flex flex-col items-center space-y-4">
                    <img src="https://replicate.delivery/pbxt/NywNPTt4yezZNSZz8e9VKVxtYmUZ7KDih28HmaNeKVgczdKlA/1711750253.jpg" alt="Storyboarding 1" className="w-56 h-28 rounded-lg"/>
                    <img src="https://replicate.delivery/pbxt/ee5sH9PUbYhJH08pIz8c0dFfK97LDOgLO0w2ZVrxVAhtyeUKB/1711752278.jpg" alt="Storyboarding 2" className="w-56 h-28 rounded-lg"/>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Generate videos</h3>
                  <p className="text-gray-300 text-center">Animate your scenes</p>
                  <div className="flex flex-col items-center">
                    <img 
                      src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wizard_video.gif?alt=media&token=1f61f58c-4f76-4516-bafb-02bdeb855fd8" 
                      alt="Animated scene" 
                      className="w-60 max-w-xs rounded-lg" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="mt-auto text-center text-gray-400 py-4">
          <p>© 2024 Eggnog. All rights reserved.</p>
          <a href="/privacy-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">Privacy Policy</a> | <a href="/eula-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">EULA</a>
        </footer>
      </div>
    </div>
  );
}
