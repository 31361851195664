import React from "react";
import eula from './eula.js'

class EULAPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: "",
    };
  }

  componentDidMount() {
    this.setState({ htmlContent:  eula})
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />;
  }
}

export default EULAPolicyPage;
