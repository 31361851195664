import React, { useState, useEffect } from 'react';
import  PromptInputGuided from './PromptInputGuided.jsx';


function MotionStillInputPromptInput({
  backgroundPrompt,
  setBackgroundPrompt,
  costumePrompt,
  setCostumePrompt,
  characterSelected,
  characterName

}) {


  const [placeholder, setPlaceholder] = useState('');
  const [promptIndex, setPromptIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [promptAreaFocused, setPromptAreaFocused] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [promptValid, setPromptValid] = useState('')
  const [text, setText] = useState('')

  

  return (
    <div className="mt-1 flex flex-col justify-between">
      <PromptInputGuided
          promptPrefix={`A film still of ${characterName} wearing`}
          prompt={costumePrompt}
          setPrompt={setCostumePrompt}
          examples={["black wizard robes", "90s British clothes", "black tuxedo"]}
          characterSelected={true}          
      />
      <PromptInputGuided
          promptPrefix={`with background`}
          prompt={backgroundPrompt}
          setPrompt={setBackgroundPrompt}
          examples={["empty basketball court", "living room of an old mansion", "modern white apartment living room"]}
          characterSelected={true}          
      />

    </div>
  );
}

export default MotionStillInputPromptInput;