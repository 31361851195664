import React, { useState, useRef } from 'react';
import Modal from './Modal';

function ChooseVoice({ isOpen, onClose, onVoiceSelected }) {
  const [selectedVoice, setSelectedVoice] = useState('');
  const [selectedVoiceLabel, setSelectedVoiceLabel] = useState('');
  const [isPlaying, setIsPlaying] = useState({});
  const audioRef = useRef(new Audio());

  // include name of voice in elevenlabs for easy management
  const voiceOptions = {
    "Male, American, emotive and smooth": "VxHkYP5YFFJw1GwcejMo", // nolan
    "Male, American, newsreader": "5kHAJ3MDKyR1vpj2y28K", // tucker
    "Female, American, introspective": "8IkSW2L63v2qwV3YHCkJ", // Joanne
    "Male, American, deep": "nuD9UsBEJDblGN0Mk6TR", // oldspice1
    "Male, British, confident": "6gMF7JQxW26ggHKbluhJ", // Draco
    "Female, British, serious": "stS1KfcwWQMKEKOlig9B", // hermoine
    "Male, British, old": "Wrwxwe9dsx53qU6jAEjq", // gregory
    "Female, American": "P7x743VjyZEOihNNygQ9", // mod1
    "Male, British, eccentric": "kYGYcASlcCF4ZWGbVy3A", // kYGYcASlcCF4ZWGbVy3A
    "Male, British, child": "1BUhH8aaMvGMUdGAmWVM", // Alyx
    "Dobby ⚡️": "CFYHD8UUfgm1V0F5k7hA", // Dobby
    "Zuck": "HOZndvjvXK43XxoSOQMK", // tech ceo
    "Male, British, young": "MajXM8GGZRjsQyWFawsR", // ron
    "PG": "Pi2Zqk51cRysbs4RoCCF", // pg
    "Male, British, husky": "T2qPBFsHUIQI1Fy8S2Zv", // arthur
    "Male, Russian": "WczBIOau2qV9z7nLeDqq", // russia
    "Male, British, young": "ZFKXOBSbQ6i7UVkp1k1t", // potter
    "Male, American, young": "g0OCHqTpO1xXO576aj14", // Dylan
    "Male, British, thoughtful": "j9jfwdrw7BRfcR43Qohk", // smooth british voice
    "Female, British, vintage": "He2X3oupwb6Qjs06PN5w", // petunia
    "Churchill": "8UyfbUVyurmhjoeQkw5l", //churchill
    "Male, Transatlantic, radio": "pHBYiezIBFZ2qCiApzmC",
    "Male, American, southern": "D4xQvkd2SmpDZJ8sEwvA", // Jett
    "Female, American, chic": "FiQcvVQvKW27OQwXwjvl", // american woman, chic
    "Trump": "nO08mPUDKcLzF4HwEa8X",
    "Pete": "XCATJYNsB1e9J5NJEZ51",
    "Obama": "knEf3coYMOvaDYDEVYrK",
    "Kim": "wAFeCOSqiz0GGM6iL6Yx",
    "Kanye": "AI0ga0SzYa603qLPyBe7",    


  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying({});
  };

  const handleVoiceSelect = (value, label) => {
    setSelectedVoice(value);
    setSelectedVoiceLabel(label);

    stopAudio(); // Stop any previously playing audio

    const audioUrl = `${process.env.PUBLIC_URL}/${value}.mp3`;
    audioRef.current.src = audioUrl;
    audioRef.current.play().then(() => {
      setIsPlaying({ [value]: true });
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying({ [value]: false });
      });
    }).catch(error => console.error("Audio play failed:", error));
  };

  const handleSubmit = () => {
    if (selectedVoice) {
      onVoiceSelected(selectedVoice, selectedVoiceLabel);
      stopAudio(); // Stop audio when submitting
      onClose(); // Optionally close the modal upon submission
    }
  };

  const handleClose = () => {
    stopAudio(); // Stop audio when closing the modal
    onClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Choose Voice" size='small'>
      <div className="grid grid-cols-2 gap-2">
        {Object.entries(voiceOptions).map(([label, value]) => (
          <button
            key={value}
            type="button"
            className={`relative py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none ${selectedVoice === value ? 'border-green-500 bg-green-500 text-white hover:bg-green-500 hover:border-green-500' : 'border-gray-300 hover:bg-gray-200 hover:border-gray-300'}`}
            onClick={() => handleVoiceSelect(value, label)}
          >
            {label}
            <span className={`absolute top-1 right-1 text-lg transition-opacity duration-300 ease-in-out ${isPlaying[value] ? 'opacity-100' : 'opacity-0'}`}>
              🔊
            </span>
          </button>
        ))}
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          onClick={handleSubmit}
          disabled={!selectedVoice}
          className={`inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white ${!selectedVoice ? 'bg-gray-300' : 'bg-blue-600 hover:bg-blue-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
        >
          Select
        </button>
      </div>
    </Modal>
  );
}

export default ChooseVoice;
