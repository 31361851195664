import React from "react";
import policy_html from './privacy_policy.js'

class PrivacyPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: "",
    };
  }

  componentDidMount() {
    this.setState({ htmlContent:  policy_html})
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />;
  }
}

export default PrivacyPolicyPage;
