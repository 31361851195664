function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return `${text.substring(0, maxLength)}...`;
  }
}

const infiniteThumbnail = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/header_thumbnails%2Feggnog_thumbnails.png?alt=media'
export {
	truncateText,
  infiniteThumbnail
}