import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function JpLandingPage() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // This function checks the width of the screen and updates the isMobile state
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // 768px is typically the width for mobile devices
    }

    // Call the function on component mount
    handleResize();

    // Add event listener to listen for screen resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100">
      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-4">
            <h1 className="text-5xl md:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 to-red-400 leading-none mb-6 py-2">Eggnog</h1>
            <p className="text-xl md:text-2xl font-light text-gray-300 max-w-2xl mx-auto leading-relaxed">Get the pookie treatment from Jett!</p>
          </header>
        <main>
          <div className='flex items-center justify-center p-2 mb-8'>
            <a href="/upload/pookie" target="_blank" rel="noopener noreferrer">
              <button className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-semibold py-3 px-8 rounded-lg transition-all duration-300 ease-in-out shadow-lg">Get started for free</button>
            </a>
          </div>
          <section className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-lg backdrop-filter backdrop-blur-md border border-gray-700">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Upload yourself</h3>
                  <p className="text-gray-300 text-center">AI learns your look</p>
                  <div className="grid grid-cols-2 gap-4">
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1714937165.jpg?alt=media&token=1feed93d-ef92-4e74-b08c-a7e8e4990c86" alt="Character 1" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1714937132.jpg?alt=media&token=fb0094d0-9bc2-4a65-a255-75a840aa1b78" alt="Character 2" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1714935314.jpg?alt=media&token=de7f5e20-5554-4211-95c4-8beac179f98c" alt="Character 4" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1714935218.jpg?alt=media&token=c11f9b21-b352-4ea1-9fa4-cc88f547905f" alt="Character 3" className="w-28 h-28 rounded-lg"/>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Describe your outfit</h3>
                  <p className="text-gray-300 text-center">Is that a Birkin bag?</p>
                  <div className="flex flex-col items-center space-y-4">
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1714938358.jpg?alt=media&token=21e1c276-b675-4ca2-b626-4955181f0942" alt="Storyboarding 1" className="w-56 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1714938604.jpg?alt=media&token=ed4a6950-b28f-4b87-8ec8-28201a5dcc26" alt="Storyboarding 2" className="w-56 h-28 rounded-lg"/>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">AI Jett compliments you</h3>
                  <p className="text-gray-300 text-center">Looking fire Pookie!</p>
                  <div className="flex flex-col items-center">
                    <img 
                      src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/resized_ac084fdc2b3c413383783cc35d214fd8.gif?alt=media&token=a059a4fd-2021-4502-a859-d189b9f697bd" 
                      alt="Animated scene" 
                      className="w-60 max-w-xs rounded-lg" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="mt-auto text-center text-gray-400 py-4">
          <p>© 2024 Eggnog. All rights reserved.</p>
          <a href="/privacy-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">Privacy Policy</a> | <a href="/eula-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">EULA</a>
        </footer>
      </div>
    </div>
  );
}
