import React, { useState, useEffect, useRef } from 'react';

export default function ImageModal({ isOpen, onClose, imageUrls }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const modalRef = useRef(); // Ref for the modal content


  const navigateImage = (direction) => {
    setCurrentImageIndex((prevIndex) => {
      let newIndex = prevIndex + direction;
      if (newIndex < 0) newIndex = imageUrls.length - 1;
      else if (newIndex >= imageUrls.length) newIndex = 0;
      return newIndex;
    });
  };

  // Close modal on click outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  // Define the function to handle key down events
  const handleKeyDown = (event) => {
    // Check if the pressed key is 'Escape'
    if (event.key === 'Escape') {
      onClose(); // Call the onClose function
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentImageIndex]);

  const isVideo = (url) => {
    return url.endsWith('.mp4?alt=media') || 
      url.endsWith('.mp4') || 
      url.endsWith('.webm') || 
      url.endsWith('.ogg');
  }

  if (!isOpen || imageUrls == null || imageUrls.length == 0) return null;
  

  return (
    
<div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50 overflow-auto">
  <div className="relative bg-black rounded-lg shadow-xl overflow-hidden" ref={modalRef} style={{ maxWidth: '96vw', maxHeight: '96vh' }}>
    <button onClick={onClose} className="absolute top-0 left-0 m-4 text-gray-300 hover:text-white z-50">
      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <div className="flex flex-col items-center justify-center h-full">
      <div className="overflow-y-auto p-10">
        {isVideo(imageUrls[currentImageIndex][0]) ? (
          <video src={imageUrls[currentImageIndex][0]} alt="Displayed" className="max-w-full max-h-[70vh] cursor-pointer object-contain" autoPlay loop onClick={() => navigateImage(1)}>
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={imageUrls[currentImageIndex][0]} alt="Displayed" className="max-w-full max-h-[70vh] cursor-pointer object-contain" onClick={() => navigateImage(1)} />
        )}
      </div>
    </div>
    <p className='text-white py-2 text-center px-4 min-h-[4rem]'>{imageUrls[currentImageIndex][1]}</p>
  </div>
</div>



  );
}