import { useState, useEffect, useRef, useCallback } from "react";

export default function PromptExplainModal({isOpen, closeModal }) {
  const modalRef = useRef(); // Create a ref for the modal

  // Click event to close the modal if clicked outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isOpen) return null

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 flex justify-center items-center text-gray-800 z-10">

      <div className="bg-white p-6 rounded-lg max-w-lg" ref={modalRef}>
        <h2 className="text-xl font-semibold mb-4">How to make a scene</h2>

        
        <div className="mb-4">
          <h3 className="font-semibold mb-2 text-lg">{`Use your character's name in the prompt`}</h3>
          <p>For example, if your character's name is  <span className="bg-gray-200 px-2 py-1 rounded text-pink-600">{'pirate-man'}</span>, then you could make prompts like</p>
          <ul className="list-disc pl-5 mt-2">
            <li>A photo of <span className="bg-gray-200 px-2 py-1 rounded text-pink-600">{'pirate-man'}</span> on a wooden ship on the ocean</li>
            <li>A photo of <span className="bg-gray-200 px-2 py-1 rounded text-pink-600">{'pirate-man'}</span> lying on the beach</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2 text-lg">{`What is your character doing?`}</h3>
          <p> A good scene will have your character doing something in a unique environment</p>
          <ul className="list-disc pl-5 mt-2">
            <li>A photo of <span className="bg-gray-200 px-2 py-1 rounded text-pink-600">{'pirate-man'}</span> holding a sword, dense rainforest jungle, green vines</li>
            <li>A photo of <span className="bg-gray-200 px-2 py-1 rounded text-pink-600">{'pirate-man'}</span> surrounded by friends, laughing, smiling</li>
          </ul>
        </div>
        <div className="mb-4">
          <h3 className="font-semibold mb-2 text-lg">Get creative to see cool results</h3>
          <p>Be creative with your prompts to achieve the best results. Incorporate keywords that define your desired outcome, such as: <span className="inline-block bg-gray-200 px-2 py-1 rounded text-pink-600">8k resolution, photorealistic, portrait, cinematic, realistic, dynamic range, cute, style of Picasso</span>These terms help guide the AI to better understand and execute your vision.</p>
        </div>

        <button onClick={closeModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Close
        </button>
      </div>
    </div>
  )
}
