import React, { useState, useEffect, useRef } from 'react';
import RangeSlider from './RangeSlider';

const TrimModal = ({ clip, onClose, onSave }) => {
  const [startTrim, setStartTrim] = useState(clip.startTrim);
  const [endTrim, setEndTrim] = useState(clip.endTrim);
  const [playbackSpeed, setPlaybackSpeed] = useState(clip.speed);
  const duration = clip.duration;
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);


   useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      // Check if the current time exceeds the end trim and loop back if necessary
      if (video.currentTime >= endTrim) {
        video.currentTime = startTrim;
        // Play the video if it's not playing. This might be redundant depending on your controls setup
        if (video.paused) {
          video.play();
        }
      }
    };

    if (video) {
      // Initialize the end trim point when the metadata is loaded
      video.addEventListener('loadedmetadata', () => {
        if (endTrim == -1) setEndTrim(video.duration);
      });

      // Listen for time updates to loop the video
      video.addEventListener('timeupdate', handleTimeUpdate);

      // Cleanup event listeners on unmount
      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [endTrim, startTrim]); 

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50"
      onClick={onClose}
      onKeyDown={(e) => e.key === 'Escape' && onClose()}
      tabIndex="0"
    >
      <div
        className="bg-white rounded-lg p-6 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <video
          src={clip.url}
          className="w-full h-auto"
          ref={videoRef}
          autoPlay
          loop
        ></video>
        <RangeSlider
          duration={duration}
          onStartChange={setStartTrim}
          onEndChange={setEndTrim}
          startValue={startTrim}
          endValue={endTrim}
          videoRef={videoRef}
        />
        <div className='flex items-center justify-center w-full'>

        <div className="flex items-center mt-4">
          <div>
            <span role="img" aria-label="turtle" className="mr-2">🐢</span>
            <input
              type="range"
              min="0.5"
              max="4"
              step="0.1"
              value={playbackSpeed}
              onChange={(e) => setPlaybackSpeed(parseFloat(e.target.value))}
              className="mx-2"
            />
            <span role="img" aria-label="rabbit" className="mx-2">🐇</span>
          </div>
          <span className="w-8 text-gray-600">{`${playbackSpeed}x`}</span>
        </div>

        </div>
        <div className='flex flex-row w-full justify-between mt-2'>
        <button
          onClick={() => onSave({
            ...clip, 
            startTrim, 
            endTrim, 
            speed: playbackSpeed, 
            duration: duration
          })}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save
        </button>
        </div>
      </div>
    </div>
  );
};

export default TrimModal;