import React from 'react';
import { FaPlay, FaVolumeMute, FaVolumeUp, FaForward } from 'react-icons/fa';

const VideoControls = ({ isPlaying, isMuted, onPlayPause, onMuteUnmute, onSkipToEnd }) => {
  return (
    <div className="bg-red-500">
      {!isPlaying && (
        <div className="absolute inset-0 flex items-center justify-center z-10" onClick={onPlayPause}>
          <button
            onClick={onPlayPause}
            className="text-white bg-purple-600 bg-opacity-80 rounded-full p-3"
          >
            <FaPlay size={40} />
          </button>
        </div>
      )}
      {!isPlaying && <div className="absolute top-2 right-2 z-10 flex flex-col items-center space-y-2">
        <button
          onClick={onMuteUnmute}
          className="text-white bg-purple-600 bg-opacity-80 rounded-full p-2"
        >
          {isMuted ? <FaVolumeMute size={25} /> : <FaVolumeUp size={25} />}
        </button>
        <button
          onClick={onSkipToEnd}
          className="text-white bg-purple-600 bg-opacity-80 rounded-full p-2"
        >
          <FaForward size={25} />
        </button>
      </div>}
    </div>
  );
};

export default VideoControls;
