import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Assuming you're using Firebase for authentication
import { auth } from '../Firebase.js'

const DropdownMenu = ({ username, specialState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const generatePath = (base) => {
    return specialState ? `/${base}/${specialState}` : `/${base}`;
  };

  return (
    <div ref={ref} className="">
      <button onClick={() => setIsOpen(!isOpen)} className="bg-gray-500 text-gray-800 font-semibold px-2 py-1 rounded inline-flex items-center">
        <span>Menu</span>
      </button>
      {isOpen && (
        <ul className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 mr-2">
          <li className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ease-in-out border-b" onClick={() => navigate(generatePath('playground'))}>Playground</li>
          <li className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ease-in-out border-b" onClick={() => navigate(generatePath('compose'))}>Stitch Videos</li>
          <li className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ease-in-out border-b" onClick={() => navigate(generatePath('profile'))}>Your Characters</li>          
          <li className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ease-in-out border-b" onClick={() => navigate(generatePath('create'))}>Create Character</li>
          <li className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ease-in-out border-b" onClick={() => navigate(generatePath('upload'))}>Upload Character</li>
          <li className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ease-in-out border-x" onClick={handleLogout}>
            <span className="block overflow-hidden text-ellipsis whitespace-normal">Logout {username}</span>
          </li>        
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
