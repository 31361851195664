import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../Firebase.js';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const token = await user.getIdToken(true);
          setIdToken(token);
        } catch (error) {
          console.error('Error fetching ID token: ', error);
        }
      } else {
        setIdToken(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = auth.onIdTokenChanged(async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken();
            setIdToken(token);
          } catch (error) {
            console.error('Error refreshing ID token: ', error);
          }
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ currentUser, loading, idToken }}>
      {children}
    </UserContext.Provider>
  );
};
