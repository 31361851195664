import React, { useState } from 'react';
import { uploadPhotoToFirebase } from '../Firebase';
import heic2any from "heic2any";

const PhotoUploader = ({ onUploadComplete, specialState }) => {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Define the header text based on specialState
  const headerText = specialState === 'pookie' 
    ? "Upload photos of you or a friend for Jett's compliment"
    : "Upload photos of you or a friend";

  const convertImageToFile = (image, originalName, format = 'image/jpeg', quality = 0.92) => {
    if (!originalName) {
      console.error('Invalid or missing file name.');
      return Promise.reject(new Error('Invalid or missing file name.'));
    }
  
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
  
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const newName = `${originalName.replace(/\.[^/.]+$/, "")}-converted.jpeg`;
          const file = new File([blob], newName, { type: format });
          resolve(file);
        } else {
          reject(new Error('Image conversion to file failed'));
        }
      }, format, quality);
    });
  };

  const convertHEIFToJPEG = async (file) => {
    if (!file || !file.name) {
      console.error('Invalid or missing file object.');
      return Promise.reject(new Error('Invalid or missing file object.'));
    }
  
    try {
      const response = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.92
      });
  
      if (!response) {
        throw new Error("No output from conversion.");
      }
  
      const newName = file.name.replace(/\.[^/.]+$/, ".jpeg");
      return new File([response], newName, { type: "image/jpeg" });
    } catch (error) {
      console.error("Conversion error for:", file.name, error);
      throw new Error(`Failed to convert HEIF/HEIC file: ${file.name}`);
    }
  };

  const readFirstBytes = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const uint = new Uint8Array(e.target.result);
        resolve(uint.slice(0, 16)); // Read first 16 bytes
      };
      reader.readAsArrayBuffer(file.slice(0, 16));
    });
  };
  
  const handleFileChange = async (event) => {
    setIsLoadingFiles(true);
    const newFiles = Array.from(event.target.files);
    setErrorMessage('');
  
    try {
      const convertedNewFiles = await Promise.all(newFiles.map(async (file) => {
        if (!file) {
          throw new Error('Received undefined file.');
        }
  
        const firstBytes = await readFirstBytes(file);
        console.log(`First bytes of ${file.name}:`, firstBytes);
  
        if (file.type === "image/heic" || file.type === "image/heif" || firstBytes[0] === 0x00) {
          console.log("Attempting to convert HEIF/HEIC file:", file.name);
          file = await convertHEIFToJPEG(file);
        }
  
        try {
          const image = await createImageBitmap(file);
          return convertImageToFile(image, file.name);
        } catch (innerError) {
          console.error("Error creating ImageBitmap for file:", file.name, innerError);
          throw innerError;
        }
      }));
  
      const newPreviews = convertedNewFiles.map(file => URL.createObjectURL(file));
      setFiles(prevFiles => [...prevFiles, ...convertedNewFiles]);
      setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
    } catch (error) {
      console.error("Error processing one or more files:", error);
      setErrorMessage("Error processing one or more files.");
    } finally {
      setIsLoadingFiles(false);
    }
  };
  

  const clearPreviews = () => {
    previews.forEach(url => URL.revokeObjectURL(url));
    setPreviews([]);
    setFiles([]);
  };

  const removeSingleFile = (index) => {
    URL.revokeObjectURL(previews[index]);
    setPreviews(prevPreviews => prevPreviews.filter((_, idx) => idx !== index));
    setFiles(prevFiles => prevFiles.filter((_, idx) => idx !== index));
  };

  const uploadFiles = async () => {
    if (files.length < 10) {
      setErrorMessage('Please add more photos to reach the minimum of 10.');
      return;
    }

    setUploading(true);
    setErrorMessage('');
    const promises = files.map(file => uploadPhotoToFirebase(file));

    try {
      const uploadResults = await Promise.all(promises);
      onUploadComplete(uploadResults);
    } catch (error) {
      console.error("Error uploading files:", error);
      setErrorMessage('Error uploading files: ' + error.message);
    } finally {
      setUploading(false);
      clearPreviews();
    }
  };

  return (
    <div className="upload-container mt-10 bg-gray-100 p-4 rounded-md max-w-4xl mx-auto">
      <div className="space-y-4">
        <div className="flex flex-col items-center">
          <h2 className="text-gray-800 text-2xl font-bold">{headerText}</h2>
          <p className="text-gray-600 text-sm mt-2">Use 10 or more high-quality face shots from different angles</p>
          {previews.length === 0 && (
            <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/facial_expressions.jpg?alt=media" alt="Example Facial Expressions" className="mt-2 max-w-xs rounded-lg"/>
          )}
        </div>
        {errorMessage && (
            <div className="text-red-500 text-center mt-2">{errorMessage}</div>
        )}
        <div className="flex justify-center">
          <label className={`file-input-wrapper bg-blue-700 text-white hover:bg-blue-800 py-3 px-6 rounded-lg block cursor-pointer ${isLoadingFiles ? 'opacity-50' : ''}`}>
            {isLoadingFiles ? (
              <div className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Loading...
              </div>
            ) : (
              <span className="text-base font-semibold block text-center">Choose Files</span>
            )}
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              disabled={uploading || isLoadingFiles}
              className="opacity-0 absolute w-1 h-1"
            />
          </label>
        </div>
        <div className="grid grid-cols-3 gap-4 overflow-auto max-h-96">
          {previews.map((preview, index) => (
            <div key={index} className="border rounded-md p-2 relative">
              <button
                onClick={() => removeSingleFile(index)}
                className="absolute right-0 top-0 bg-red-600 hover:bg-red-700 text-white font-bold p-1 rounded-full text-xs"
                style={{ zIndex: 10 }}
              >
                X
              </button>
              <img src={preview} alt="Preview" className="max-h-40 max-w-full"/>
            </div>
          ))}
        </div>
        {files.length > 0 && (
          <div className="flex justify-between mt-4">
            <button
              onClick={clearPreviews}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Clear
            </button>
            <button
              onClick={uploadFiles}
              disabled={uploading}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              {uploading ? 'Uploading...' : 'Upload All'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoUploader;
