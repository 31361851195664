import { useState, useEffect } from 'react';
import Modal from '../Components/Modal.jsx';
import { truncateText } from '../utils.js';

export default function AllCharactersModal({ customCharacters, stockCharacters, setCharacter, onClose }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCharacters, setFilteredCharacters] = useState([]);

  // Add a term to differentiate
  const custom = customCharacters.map(c => ({ ...c, type: 'Custom' }));
  const stock = stockCharacters.map(c => ({ ...c, type: 'Default' }));

  const createCustomCharacterDiv = (
    <div
      className="flex items-center justify-center bg-green-500 text-white font-bold p-4 rounded-lg cursor-pointer min-h-72"
      onClick={() => window.open('/create', '_blank')}
    >
      Create new character
    </div>
  );

  const uploadNewCharacterDiv = (
    <div
      className="flex items-center justify-center bg-blue-500 text-white font-bold p-4 rounded-lg cursor-pointer min-h-72"
      onClick={() => window.open('/upload', '_blank')}
    >
      Upload yourself or a friend
    </div>
  );

  const SEARCH_ALL_TERMS = true; // False will match if at least one keyword matches a character
  useEffect(() => {
    if (searchTerm.length > 3) {
      const searchTerms = searchTerm.toLowerCase().split(' ').filter(x => x !== '');

      const searchResults = [...custom, ...stock].filter(character => {
        const matchLogic = SEARCH_ALL_TERMS ? term =>
          character.character_name?.toLowerCase().includes(term) ||
          character.character_details?.toLowerCase().includes(term) ||
          character.character_type?.toLowerCase().includes(term)
          :
          term =>
            character.character_name?.toLowerCase().includes(term) ||
            character.character_details?.toLowerCase().includes(term) ||
            character.character_type?.toLowerCase().includes(term);

        return SEARCH_ALL_TERMS ? searchTerms.every(matchLogic) : searchTerms.some(matchLogic);
      });

      setFilteredCharacters(searchResults);
    } else {
      setFilteredCharacters([...custom, ...stock]);
    }
  }, [searchTerm, customCharacters, stockCharacters]);

  return (
    <Modal isOpen={true} handleClose={onClose} size="small">
      <div className="modal-content h-full flex flex-col">
        <div className="sticky top-0 bg-white z-10 p-4">
          <h2 className="text-lg font-bold">Find a character</h2>
          <input
            type="text"
            placeholder="Search characters..."
            className="w-full p-2 border border-gray-300 rounded-lg"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex-1 overflow-y-auto p-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 gap-4 mt-4">
            {createCustomCharacterDiv}
            {uploadNewCharacterDiv}
            {filteredCharacters.map((character, index) => (
              <CharacterCard
                key={index}
                character={character}
                setCharacter={() => {
                  setCharacter(character);
                  onClose();
                }}
                cardType="Grid"
                tagColor={character.type == 'Default' ? 'gray' : 'blue'}
                tagText={character.type}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

function CharacterCard({ character, setCharacter, isSelected, cardType, isGrid, onOpenChangeModal, tagColor, tagText }) {
  // Layout for full-width character card with optional "Change" button
  const truncatedDescription = truncateText(character.character_details || "No details provided", 60);
  const tagStyle = `bg-${tagColor}-500 text-white text-xs font-medium px-1 rounded-full absolute top-0 right-0 m-2`;

  if (cardType == "Freestyle") {
    return (
      <div
        className={`cursor-pointer rounded-lg flex ${isSelected ? "border-4 border-blue-500" : "border border-gray-300"} hover:border-gray-500 relative max-h-72`}
        onClick={setCharacter}
      >
        <div style={{ width: '30%', height: '100%', position: 'relative' }}>
          <img
            src={character.low_quality_reference_face_url || character.reference_face_url}
            alt={character.character_name || "No character"}
            className="object-cover w-full h-full rounded-l-lg"
          />
        </div>
        <div className="flex-1 flex flex-col pl-4">
          <h3 className="text-lg font-bold">{character.character_name || "Unnamed Character"}</h3>
          <p className="text-sm text-gray-700">{character.character_details || "No details provided"}</p>
          {tagText && <span className={tagStyle}>{tagText}</span>}
        </div>
      </div>
    );

  } else if (cardType == "Use") {
    return (
      <div
        className={`cursor-pointer rounded-lg flex ${isSelected ? "border-4 border-blue-500" : "border border-gray-300"} hover:border-gray-500 relative max-h-72`}
      >
        <div style={{ width: '30%', height: '100%', position: 'relative' }}>
          <img
            src={character.low_quality_reference_face_url || character.reference_face_url}
            alt={character.character_name || "No character"}
            className="object-cover w-full h-full rounded-l-lg"
          />
        </div>
        <div className="flex-1 flex flex-col justify-center pl-4 relative">
          <h3 className="text-lg font-bold">{character.character_name || "Unnamed Character"}</h3>
          <p className="text-sm text-gray-700">{character.character_details || "No details provided"}</p>
          {tagText && <span className={tagStyle}>{tagText}</span>}
          {onOpenChangeModal && (
            <button
              className="absolute bottom-0 right-0 mb-2 mr-2 text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
              onClick={(e) => {
                e.stopPropagation();
                onOpenChangeModal();
              }}
            >
              Change
            </button>
          )}
        </div>
      </div>
    );
  } else if (cardType == "Grid") {
    return (
      <div
        className={`cursor-pointer rounded-lg flex flex-col items-center ${isSelected ? "border-4 border-blue-500" : "border border-gray-300"} hover:border-gray-500 relative max-h-72`}
        onClick={setCharacter}
      >
        <div style={{ width: '100%', height: '80%', position: 'relative' }}>
          <img src={character.low_quality_reference_face_url || character.reference_face_url} alt={character.character_name || "No character"} className="w-full h-full object-cover rounded-t-lg" />
          {tagText && <span className={tagStyle}>{tagText}</span>}
        </div>
        <div className="pt-2 pb-1 px-2 w-full text-left">
          <h3 className="text-xs font-bold">{character.character_name || "Unnamed Character"}</h3>
          <p className="text-xs text-gray-700">{truncatedDescription}</p>
        </div>
      </div>
    );    
  }
}
