import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useUser } from '../contexts/UserContext';
import VideoItem from './VideoItem';
import ProgressBar from '../Components/ProgressBar';

const CompletedVideos = ({ completedVideos }) => {
  const videosPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const { currentUser, loading } = useUser();

  const reversedVideos = [...completedVideos].reverse();

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = reversedVideos.slice(indexOfFirstVideo, indexOfLastVideo);

  let totalPages = Math.ceil(reversedVideos.length / videosPerPage);
  if (reversedVideos.length === 0) totalPages = 1;

  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  if (loading) return <CircularProgress />;

  return (
    <div className="w-full h-1/2 p-4 overflow-y-auto border mt-4">
      <h2 className="text-xl text-gray-800 font-semibold mb-4">Combined Videos</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {currentVideos.map((video, index) => (
          video.status === "PROCESSING" ? (
            <div key={index} className="flex justify-center items-center h-full">
              <ProgressBar 
                duration={10000} 
                onCompletion={() => console.log('Reached 95%')} 
                key={video.prediction_id} 
              />            
            </div>
          ) : (
            <VideoItem key={index} video={video} currentUser={currentUser} />
          )
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4 mt-4">
        {currentPage > 1 && (
          <button onClick={goToPreviousPage} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Previous
          </button>
        )}
        <span>Page {currentPage} of {totalPages}</span>
        {currentPage < totalPages && (
          <button onClick={goToNextPage} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default CompletedVideos;