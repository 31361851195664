export const defaultPreloadAssetArray = [
    {
      asset_type: "PHOTO",
      model_url: "samplank/s9--at94389--p--dot94389--com",
      negative_prompt: "((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs)), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w",
      output_images: ["https://replicate.delivery/pbxt/gYRY0l0ROHKyHF7HZdbKfRHBfKLQ4fisVY8pBeOdRj0aFPdKB/1712294485.jpg"],
      prediction_id: "s9@p.com-2024-04-05T05:20:49.636Z",
      prompt: "A film scene of Sophon swinging a katana violently, screaming, in the desert",
      status: "COMPLETE",
      timestamp: new Date("2024-04-05T05:20:49.636Z")
    },
    {
      asset_type: "VIDEO",
      model_url: "https://replicate.com/samplank/s9--at94389--p--dot94389--com",
      negative_prompt: "",
      output_images: ["https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wm_local_d9743070a5e94a72b219b814c86f061b_000007.mp4?alt=media"],
      prediction_id: "s9@p.com-2024-04-05T05:22:15.893Z",
      prompt: "",
      status: "COMPLETE",
      timestamp: new Date("2024-04-05T05:22:15.893Z")
    },
    {
        asset_type: "PHOTO",
        model_url: "https://replicate.com/stability-ai/sdxl",
        negative_prompt: "((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs)), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w",
        output_images: ["https://replicate.delivery/pbxt/eFHCawew2hlDdUCJmcfe8dePfwIxqOLbt70Xf8a8DpOFlehnSA/out-0.png"],
        prediction_id: "s11@p.com-2024-04-05T21:29:35.838Z",
        prompt: "A film scene of a giant wave crashing on a beach",
        status: "COMPLETE",
        timestamp: new Date("2024-04-05T21:29:35.838Z")
    },
    {
        asset_type: "VIDEO",
        model_url: "https://replicate.com/stability-ai/sdxl",
        negative_prompt: "",
        output_images: ["https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wm_local_244172ee86f94a8083703f8eb510e50e_000003.mp4?alt=media"],
        prediction_id: "s11@p.com-2024-04-05T21:31:05.581Z",
        prompt: "",
        status: "COMPLETE",
        timestamp: new Date("2024-03-30T05:10:12.196212+00:00")
    },
    {
      asset_type: "PHOTO",
      model_url: "samplank/g91dqwjlethxte7izfv2ihlf2cs2",
      negative_prompt: "((nudity)), multiple people, easynegative, canvasframe, canvas frame, blurry, hands, (easynegative), ((((ugly)))), (((duplicate))), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, blurry, (((bad proportions))), ((extra limbs)), cloned face, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w",
      output_images: ["https://replicate.delivery/pbxt/NIiSJ2sn5n5eeUaMBfSZMhfN59KBTcWuxmDjHk1sFCqKtHeUC/1712352465.jpg"],
      prediction_id: "s11@p.com-2024-04-05T21:27:22.766Z",
      prompt: "A film scene of Buffy in a dive bar",
      status: "COMPLETE",
      timestamp: new Date("2024-04-05T21:27:22.766Z")
    },
    {
      asset_type: "VIDEO",
      model_url: "samplank/g91dqwjlethxte7izfv2ihlf2cs2",
      negative_prompt: "",
      output_images: ["https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/wm_e37138a58dcf4cd3b2874d72457d3bfa_result_voice.mp4?alt=media"],
      prediction_id: "s11@p.com-2024-04-05T21:28:06.685Z",
      prompt: "",
      status: "COMPLETE",
      timestamp: new Date("2024-04-05T21:28:45.877964+00:00")
    },
  ];