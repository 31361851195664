import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, saveUploadedCharacterPhotos } from '../Firebase.js';
import { doc, setDoc, onSnapshot, collection } from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';

import PhotoUploader from '../Components/PhotoUploader.jsx';
import DescriptionInput from '../Components/DescriptionInput.jsx';
import NameInput from '../Components/NameInput.jsx';
import MenuBar from '../MenuBar';

const UPLOAD_STATE = {
  UploadPhotos: 0,
  AddDescription: 1,
  NamePerson: 2,
  Confirm: 3,
};

const UploadCharacter = () => {
  const navigate = useNavigate();
  const { specialState } = useParams();
  const [step, setStep] = useState(UPLOAD_STATE.UploadPhotos);
  const [photos, setPhotos] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [description, setDescription] = useState('');
  const [personName, setPersonName] = useState('');
  const [userData, setUserData] = useState(null);
  const { currentUser, loading, idToken } = useUser();

  const username = currentUser?.email || currentUser?.uid;

  useEffect(() => {
    if (!username) return;
    const docRef = doc(db, "character-users", username);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setUserData(data);
      } else {
        console.log("No such document!");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  const trackEvent = (eventName, eventData) => {
    const data = {
        user: currentUser.email,
        event_name: eventName,
        ...eventData
    };

    fetch('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/log', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        console.log('Event tracked successfully:', data);
    })
    .catch((error) => {
        console.error('Error tracking event:', error);
    });
  };

  if (loading) return <div>Loading...</div>;
  if (currentUser == null) {
    const loginPath = specialState ? `/login/${specialState}` : '/login';
    navigate(loginPath);
  }

  const nextStep = () => setStep((prev) => prev + 1);
  const previousStep = () => setStep((prev) => prev - 1);

  const handlePhotoUpload = (uploadedPhotos) => {
    setPhotos(uploadedPhotos);
    setThumbnail(uploadedPhotos[0]);
    nextStep();
  };

  const handleDescriptionInput = (desc) => {
    setDescription(desc);
    nextStep();
  };

  const handleNameInput = (name) => {
    setPersonName(name);
    nextStep();
  };

  const handleThumbnailSelect = (photoUrl) => {
    setThumbnail(photoUrl);
  };

  const handleSubmit = async () => {
    let route;
    try {
      const baseData = {
        user: username,
        photos: photos,
        reference_face_url: thumbnail,
        character_details: description,
        character_name: personName
      };
  
      let data = {...baseData};  // Clone baseData to allow modifications
  
      if (specialState === 'pookie') {
        // Create a new document in the 'template-results' subcollection
        const newDocRef = doc(collection(db, `character-users/${username}/template-results`));
        data = {
          ...baseData,
          template_type: 'jett',
          template_result_doc_id: newDocRef.id,
          is_complete: false
        };
        await setDoc(newDocRef, data);
  
        route = 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com/upload_character_template'
      } else {
        route = 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com/upload_character'
      }

      const response = await fetch(route, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,

        },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
        const responseBody = await response.json();
        trackEvent('upload-character', {});
  
        // Redirect based on the presence of the special state 'pookie'
        if (specialState === 'pookie') {
          navigate(`/result/${specialState}/${data.template_result_doc_id}`);
        } else {
          navigate('/profile');
        }
      } else {
        throw new Error('Failed to upload character: Server responded with status ' + response.status);
      }
    } catch (error) {
      console.error("Failed to upload character:", error);
    }
  };    

  return (
  <div className="w-screen h-screen bg-gray-100 flex justify-center items-center">
    <MenuBar />
    <div className="bg-gray-100 p-4 rounded-md shadow-lg max-w-4xl mx-auto my-4">
      {/* Steps can be rendered as components for clarity */}
      {step === UPLOAD_STATE.UploadPhotos && (
        <PhotoUploader onUploadComplete={handlePhotoUpload} specialState={specialState} />
      )}
      {step === UPLOAD_STATE.AddDescription && (
        <DescriptionInput
          onDescriptionComplete={handleDescriptionInput}
          backButton={previousStep}
          specialState={specialState}
        />
      )}
      {step === UPLOAD_STATE.NamePerson && (
        <NameInput
          onNameComplete={handleNameInput}
          backButton={previousStep}
        />
      )}
      {step === UPLOAD_STATE.Confirm && (
        <div className="upload-container text-center">
          <h2 className="text-gray-800 text-2xl font-bold mb-4">Finish and upload</h2>
          <div className="text-left mx-auto px-4">
            <p><strong>Description:</strong> {description}</p>
            <p><strong>Name:</strong> {personName}</p>
            <div>
              <h3 className="font-bold text-lg">Choose Thumbnail:</h3>
              <div className="overflow-auto" style={{ maxHeight: '300px' }}> 
                <div className="grid grid-cols-3 gap-2">
                  {photos.map((photo, index) => (
                    <div key={index} className="border rounded-md p-1 relative">
                      <img
                        src={photo}
                        alt="Thumbnail Option"
                        className={`cursor-pointer ${thumbnail === photo ? 'ring-4 ring-blue-500' : 'ring-2 ring-gray-300'}`}
                        onClick={() => handleThumbnailSelect(photo)}
                        style={{ width: '100%', objectFit: 'cover' }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <p className="text-gray-600 text-sm mt-4">Your uploaded character will only be visible to you.</p>
          <div className="flex justify-between mt-4 px-4">
            <button onClick={previousStep} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
              Back
            </button>
            <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Finish
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default UploadCharacter;
